import React, { ReactElement } from 'react';
import { Modal, Row, Column, Spacing, Text, Button } from '@dls/web';
import { trans as t } from '../../helpers/localisation';
import { StyledErrorButtonLayout } from '../AddonCatalog/addonStyles';
import { APP_TYPE_ANY } from '../../types/common.types';

interface TProps {
  onCtaClick?: () => void;
  onClose?: () => void;
  onSecondaryCtaClick?: () => void;
  isModalOpen?: boolean;
}

const SilverInfoModal = (props: TProps): ReactElement => {
  const { onCtaClick, isModalOpen = false, onClose } = props;

  return (
    <Modal
      data-testid={`silver-info-modal`}
      visible={isModalOpen}
      title={t('SILVER_CUS_INFO_TITLE') as string}
      closable={true}
      onClose={onClose}
      wide={true}
      backdropClosable={false}
    >
      {
        (
          <Modal.Content>
            <Spacing bottom={2}>
              <Text type="body">{t('SILVER_CUS_INFO_DESCRIPTION')}</Text>
            </Spacing>

            <Row>
              <Column noGutter xs={12} sm={6} md={6}>
                <StyledErrorButtonLayout>
                  <Button
                    data-testid="silver-info-modal-proceed"
                    onClick={onCtaClick}
                  >
                    {t('SILVER_CUS_INFO_PROCEED')}
                  </Button>
                </StyledErrorButtonLayout>
              </Column>
            </Row>
          </Modal.Content>
        ) as APP_TYPE_ANY
      }
    </Modal>
  );
};

export default SilverInfoModal;
