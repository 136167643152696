import {
  fulfillmentBillingDetailsActions,
  fulfillmentDeliveryActions,
  ACTION_TYPES as MODULE_ACTION_TYPES
} from '@detox/actions';
import {
  GeoGraphicAddress,
  ValidFloor
} from '../../types/registrationCheckout';
import CONSTANTS from '../../constants/checkout';
import FULFILMENT_CONSTANTS from '../../constants/fulfillment';
import { KeyValue } from '../../types/common.types';
import { Popstation } from '@detox/actions/dist/types/types/fulfillment.types';
import {
  DeliveryState,
  ExistingAddress,
  NoteSection,
  PickupMode
} from '../../types/fulfilment.types';
import translate from '../../hooks/useTranslation';
import { ACTION_TYPES } from '../../constants/actions';

const GoogleMapKey = process.env.GATSBY_GOOGLE_MAP_API_KEY;

interface InitDataPayload {
  note: NoteSection;
  pickup: PickupMode[];
  productList: KeyValue[];
}

const {
  GET_DELIVERY_DETAILS_LOADING,
  GET_DELIVERY_DETAILS_ERROR,
  GET_DELIVERY_DETAILS_SUCCESS,
  GET_APPOINTMENT_SLOT_LOADING,
  GET_APPOINTMENT_SLOT_SUCCESS,
  GET_APPOINTMENT_SLOT_ERROR,
  CONTACT_VIEW_SUCCESS,
  CONTACT_VIEW_FAILURE
} = fulfillmentDeliveryActions.DELIVERY_ACTIONS;

const {
  FETCH_BILLING_PREFERENCE_LOADING,
  FETCH_BILLING_PREFERENCE_SUCCESS,
  FETCH_BILLING_PREFERENCE_ERROR
} = fulfillmentBillingDetailsActions.BILLING_DETAILS;

const initialValues: DeliveryState = {
  isLoading: false,
  isDeliveryLoading: false,
  isError: false,
  newAddressDefault: true,
  collectAtStoreTimeSlots: null,
  submitSuccess: false,
  deliveryModeLoading: {
    collectAtStore: false
  },
  deliveryDataModel: {
    bags: [],
    googleMapKey: GoogleMapKey,
    validFloors: null
  },
  collectAtStoreAppointments: null,
  casAppointmentCreated: false,
  deliveryOptions: null,
  promoCodeInfo: null,
  storeEAppData: null,
  cartCleared: false,
  hasConfirmedFulfilment: false,
  skeletonLoading: false,
  contactUpdated: false
};

export const deliveryReducer = (
  state: DeliveryState = initialValues,
  action
): DeliveryState => {
  const { t } = translate();

  switch (action.type) {
    case FETCH_BILLING_PREFERENCE_LOADING:
    case GET_DELIVERY_DETAILS_LOADING: {
      return {
        ...state,
        skeletonLoading: true,
        isDeliveryLoading: true,
        isError: false,
        cartCleared: false
      };
    }

    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SUBMIT_FULFILLMENT_LOADING:
    case CONSTANTS.CALLER_BY_CONTACT_LOADING:
    case ACTION_TYPES.CART.EMPTY_CART_LOADING: {
      return {
        ...state,
        isLoading: true,
        isDeliveryLoading: true,
        isError: false,
        cartCleared: false
      };
    }
    case FETCH_BILLING_PREFERENCE_SUCCESS: {
      return {
        ...state,
        skeletonLoading: false,
        isError: false
      };
    }
    case CONSTANTS.CALLER_BY_CONTACT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false
      };
    }
    case GET_DELIVERY_DETAILS_SUCCESS: {
      return {
        ...state,
        skeletonLoading: false,
        isDeliveryLoading: false,
        isError: false,
        deliveryDetails: action.payload
      };
    }

    case ACTION_TYPES.CHECKOUT.UPDATE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        deliveryDetails: action.value?.deliveryDetails
      };
    }

    case CONSTANTS.CALLER_BY_CONTACT_ERROR:
    case ACTION_TYPES.CART.EMPTY_CART_ERROR:
    case FETCH_BILLING_PREFERENCE_ERROR:
    case GET_DELIVERY_DETAILS_ERROR: {
      return {
        isError: true,
        isLoading: false,
        isDeliveryLoading: false,
        skeletonLoading: false
      };
    }
    case 'GET_ADDRESS_INFO_LOADING':
    case GET_APPOINTMENT_SLOT_LOADING: {
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    }
    case GET_APPOINTMENT_SLOT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        appointmentSlots: action.payload
      };
    }
    case GET_APPOINTMENT_SLOT_ERROR: {
      return {
        ...state,
        isLoading: false,
        isDeliveryLoading: false,
        isError: true,
        redirectNeeded: false,
        errorStep: 'delivery_appointment_slot',
        deliveryDataModel: {
          ...state.deliveryDataModel,
          bags: [
            {
              ...state.deliveryDataModel?.bags?.[0],
              deliverySlot: null
            }
          ]
        }
      };
    }
    case 'GET_ADDRESS_INFO_SUCCESS': {
      return {
        ...state,
        isLoading: false,
        isError: false,
        addressInfo: action.payload
      };
    }
    case 'GET_ADDRESS_INFO_FAILED': {
      return {
        ...state,
        isLoading: false,
        isDeliveryLoading: false
      };
    }
    case CONSTANTS.UPDATE_DELIVERY_ADDRESSES: {
      const newAddressFromUser = { billingAccountDetails: [action.payload] };
      return {
        ...state,
        deliveryDetailsFromUser: newAddressFromUser
      };
    }
    case CONSTANTS.UPDATE_DELIVERY_ADDRESS_SELECTION:
      return {
        ...state,
        newAddressDefault: action.payload
      };
    case CONSTANTS.RESET_CHECKOUT_FORM_DATA:
      return {
        ...state,
        deliveryDetailsFromUser: null
      };
    case CONSTANTS.SET_COLLECT_AT_STORE_TIME_SLOTS_SUCCESS: {
      const { totalActiveSlots, implEAppointmentTimeSlots } =
        action.payload || {};

      if (totalActiveSlots === 0) {
        return {
          ...state,
          isError: true,
          errorTitle: t('TIME_SLOTS_UNAVAILABLE'),
          errorMessage: t('TIME_SLOTS_UNAVAILABLE_MSG'),
          redirectNeeded: false,
          collectAtStoreTimeSlots: [],
          deliveryModeLoading: {
            ...state.deliveryModeLoading,
            collectAtStore: false
          }
        };
      }

      return {
        ...state,
        isError: false,
        collectAtStoreTimeSlots: implEAppointmentTimeSlots,
        deliveryModeLoading: {
          ...state.deliveryModeLoading,
          collectAtStore: false
        }
      };
    }

    case ACTION_TYPES.FULFILMENT.SET_COLLECT_AT_STORE_TIME_SLOTS_LOADING: {
      return {
        ...state,
        deliveryModeLoading: {
          ...state.deliveryModeLoading,
          collectAtStore: true
        }
      };
    }
    case CONSTANTS.EAPPOINTMENT_CREATE_EXISTING_APPT: {
      const errorResponse = action.payload;

      const appointmentExist = errorResponse?.[0]?.errorCode === '301';

      return {
        ...state,
        isLoading: false,
        isDeliveryLoading: false,
        isError: true,
        errorTitle: appointmentExist
          ? t('EXISTING_APPOINTMENT_TITLE')
          : t('TIME_SLOTS_UNAVAILABLE'),
        errorMessage: appointmentExist
          ? t('EXISTING_APPOINTMENT_BODY')
          : t('TIME_SLOTS_UNAVAILABLE_MSG'),
        redirectNeeded: false
      };
    }

    case CONSTANTS.EAPPOINTMENT_CREATE: {
      const { apptId, apptState, ...rest } = action.response?.appointment || {};
      const { dealerCode } = rest?.site || {};

      const storeApptData = {
        eApptId: apptId,
        eApptState: apptState,
        storeAptDetails: rest,
        dealerCode
      };

      return {
        ...state,
        storeEAppData: storeApptData,
        casAppointmentCreated: true
      };
    }

    case CONSTANTS.EAPPOINTMENT_CLEAR: {
      return {
        ...state,
        storeEAppData: null
      };
    }
    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SUBMIT_FULFILLMENT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isDeliveryLoading: false,
        isError: false,
        submitSuccess: true
      };
    }
    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SUBMIT_FULFILLMENT_ERROR: {
      const errorCode = action.error;
      const { errorInfo } = action.errorInfo || {};
      let errorModalData = {};

      if (errorCode === 'OUT_OF_STORE_STOCK') {
        errorModalData = {
          errorTitle: t('DEVICE_UNAVAILABLE'),
          errorMessage: t('DEVICE_UNAVAILABLE_MSG')
        };
      }

      return {
        ...state,
        isLoading: false,
        isDeliveryLoading: false,
        isError: true,
        errorInfo: errorInfo,
        submitSuccess: false,
        ...errorModalData
      };
    }
    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.RESET_FULFILMENT: {
      return {
        ...state,
        submitSuccess: false
      };
    }

    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.INIT_DATA: {
      const { note, pickup, productList } = action.payload as InitDataPayload;
      return {
        ...state,
        isLoading: false,
        contactUpdated: false,
        isDeliveryLoading: false,
        deliveryDataModel: {
          ...state.deliveryDataModel,
          bags: [
            {
              id: '',
              popstationTimeslots: [],
              address: {},
              popstations: null,
              note: note,
              pickup: pickup,
              items: productList,
              deliverySlot: null
            }
          ]
        }
      };
    }

    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS
      .SET_DELIVERY_EXISTING_ADDRESS: {
      return {
        ...state,
        deliveryDataModel: {
          ...state.deliveryDataModel,
          existingAddresses: action.payload
        }
      };
    }

    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_POPSTATION_TIMESLOTS: {
      return {
        ...state,
        deliveryDataModel: {
          ...state.deliveryDataModel,
          bags: [
            {
              ...state.deliveryDataModel.bags[0],
              popstationTimeslots: action.payload
            }
          ]
        }
      };
    }

    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_POPSTATIONS: {
      return {
        ...state,
        deliveryDataModel: {
          ...state.deliveryDataModel,
          bags: [
            {
              ...state.deliveryDataModel?.bags?.[0],
              popstations: action.payload
            }
          ]
        }
      };
    }
    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_DELIVERY_TIMESLOTS: {
      return {
        ...state,
        deliveryDataModel: {
          ...state.deliveryDataModel,
          bags: [
            {
              ...state.deliveryDataModel?.bags?.[0],
              deliverySlot: action.payload
            }
          ]
        }
      };
    }

    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_CONFIRM_ADDRESS: {
      return {
        ...state,
        deliveryDataModel: {
          ...state.deliveryDataModel,
          confirmAddress: action.payload
        }
      };
    }

    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_VALID_FLOORS_ADDRESS: {
      return {
        ...state,
        deliveryDataModel: {
          ...state.deliveryDataModel,
          validFloors: action.payload
        }
      };
    }

    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_ADDRESS: {
      return {
        ...state,
        deliveryDataModel: {
          ...state.deliveryDataModel,
          address: action.payload
        }
      };
    }

    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS
      .SET_COLLECT_AT_STORE_TIME_SLOTS: {
      const validSlots = (action.payload || []).filter(
        ({ disabled = false }) => !disabled
      );

      return {
        ...state,
        deliveryDataModel: {
          ...state.deliveryDataModel,
          collectAtStoreSlots: validSlots
        }
      };
    }

    case CONTACT_VIEW_SUCCESS: {
      return {
        ...state,
        collectAtStoreAppointments: action.payload
      };
    }

    case CONTACT_VIEW_FAILURE: {
      return {
        ...state,
        collectAtStoreAppointments: null
      };
    }
    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_DELIVERY_MODE: {
      return {
        ...state,
        selectedDeliveryMode: action.payload
      };
    }

    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.RESET_DELIVERY_MODE: {
      return {
        ...state,
        selectedDeliveryMode: null
      };
    }

    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.ADD_PROMO_CODE_SUCCESS: {
      return {
        ...state,
        promoCodeInfo: {
          errorMessage: null,
          isValid: true,
          promoCode: action.value,
          isLoading: false
        }
      };
    }

    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.ADD_PROMO_CODE_LOADING: {
      return {
        ...state,
        promoCodeInfo: {
          promoCode: action.value,
          errorMessage: null,
          isValid: null,
          isLoading: true
        }
      };
    }

    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.ADD_PROMO_CODE_ERROR: {
      return {
        ...state,
        promoCodeInfo: {
          ...state.promoCodeInfo,
          errorMessage: action.value,
          isValid: false,
          isLoading: false
        }
      };
    }
    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.RESET_PROMO_DATA: {
      return {
        ...state,
        promoCodeInfo: null
      };
    }

    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_PRESELECT_DELIVERY_DATA: {
      return {
        ...state,
        preselectDeliveryData: action.payload
      };
    }

    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_DELIVERY_OPTIONS: {
      return {
        ...state,
        deliveryOptions: action.payload
      };
    }

    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS
      .CLEAR_PRESELECT_DELIVERY_DATA: {
      return {
        ...state,
        preselectDeliveryData: {}
      };
    }

    case ACTION_TYPES.CART.EMPTY_CART_SUCCESS: {
      return {
        ...state,
        cartCleared: true,
        isLoading: false,
        isDeliveryLoading: false,
        isError: false
      };
    }
    case ACTION_TYPES.ORDER.RESET_ORDER: {
      return {
        ...state,
        cartCleared: false
      };
    }
    case ACTION_TYPES.DELIVERY.CLEAR_DELIVERY:
    case ACTION_TYPES.PAYMENT.CLEAR_BUY_FLOW_DATA: {
      return initialValues;
    }
    case ACTION_TYPES.DELIVERY.RESET_ERROR: {
      const updateState = {
        ...state,
        isError: false,
        errorTitle: '',
        errorMessage: '',
        redirectNeeded: false,
        errorStep: '',
        errorInfo: null
      };
      const { redirectNeeded, errorInfo, ...rest } = updateState;

      return rest;
    }
    case FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.HAS_CONFIRMED_FULFILMENT: {
      return {
        ...state,
        hasConfirmedFulfilment: action.value
      };
    }

    case MODULE_ACTION_TYPES.ORDER_SUMMARY.CUSTOMER_CONTACT_UPDATE_SUCCESS: {
      return {
        ...state,
        contactUpdated: true
      };
    }
    default:
      return state;
  }
};

export default deliveryReducer;

export const setExistingAddress = (payload: ExistingAddress) => {
  return dispatch => {
    dispatch({
      type:
        FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_DELIVERY_EXISTING_ADDRESS,
      payload
    });
  };
};

export const initData = (payload: InitDataPayload) => {
  return dispatch => {
    dispatch({
      type: FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.INIT_DATA,
      payload
    });
  };
};
export const setPopstationTimeslots = (payload: string[]) => {
  return dispatch => {
    dispatch({
      type: FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_POPSTATION_TIMESLOTS,
      payload
    });
  };
};

export const setPopstations = (payload: Popstation[]) => {
  return dispatch => {
    dispatch({
      type: FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_POPSTATIONS,
      payload
    });
  };
};

export const setConfirmAddress = (payload: GeoGraphicAddress) => {
  return dispatch => {
    dispatch({
      type: FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_CONFIRM_ADDRESS,
      payload
    });
  };
};

export const setValidFloorForAddress = (payload: ValidFloor[]) => {
  return dispatch => {
    dispatch({
      type: FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_VALID_FLOORS_ADDRESS,
      payload
    });
  };
};

export const setAddress = (payload: KeyValue[]) => {
  return dispatch => {
    dispatch({
      type: FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_ADDRESS,
      payload
    });
  };
};
export const setDeliveryTimeSlots = (payload: KeyValue) => {
  return dispatch => {
    dispatch({
      type: FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_DELIVERY_TIMESLOTS,
      payload
    });
  };
};

export const setCollectAtStoreTimeSlots = (payload: KeyValue[]) => {
  return dispatch => {
    dispatch({
      type:
        FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_COLLECT_AT_STORE_TIME_SLOTS,
      payload
    });
  };
};

export const setDeliveryMode = (deliveryMode: string) => dispatch => {
  dispatch({
    type: FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_DELIVERY_MODE,
    payload: deliveryMode
  });
};

export const resetDeliveryMode = () => dispatch => {
  dispatch({
    type: FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.RESET_DELIVERY_MODE
  });
};

export const setPreSelectDeliveryData = (payload: KeyValue) => dispatch => {
  dispatch({
    type: FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_PRESELECT_DELIVERY_DATA,
    payload
  });
};

export const setDeliveryOptions = (payload: KeyValue) => dispatch => {
  dispatch({
    type: FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.SET_DELIVERY_OPTIONS,
    payload
  });
};

export const clearPreSelectDeliveryData = () => dispatch => {
  dispatch({
    type: FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.CLEAR_PRESELECT_DELIVERY_DATA
  });
};

export const showCollectAtStoreSlotError = () => dispatch => {
  dispatch({
    type: CONSTANTS.SET_COLLECT_AT_STORE_TIME_SLOTS_SUCCESS,
    payload: { totalActiveSlots: 0 }
  });
};

export const resetPromoData = () => dispatch => {
  dispatch({
    type: FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.RESET_PROMO_DATA
  });
};

export const eApptClear = () => dispatch => {
  dispatch({
    type: CONSTANTS.EAPPOINTMENT_CLEAR
  });
};

export const setConfirmFulfilment = confirmed => dispatch => {
  dispatch({
    type: FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.HAS_CONFIRMED_FULFILMENT,
    value: confirmed
  });
};
