import React, { useEffect, useState } from 'react';
import { trans as t } from '../../helpers/localisation';
import { getPersonalInfo } from '../../components/OrderSummary/helper';
import { ActionTypes } from '../../types/orderSummary.types';
import ChangeContactDetails from '../../components/OrderSummary/ChangeContactDetails';
import { ChangeContactField, UserInformation } from '../../types/user.types';
import { useSelector } from 'react-redux';
import { KeyValue } from '../../types/common.types';
import { Checkout } from '../../types/checkout.types';
import { Fulfilment } from '../../types/fulfilment.types';

export const useChangeContactModal = (callback?: () => void) => {
  const [showContactModal, setShowContactModal] = useState(false);

  const { checkout, userInformation, delivery } = useSelector(
    (state: KeyValue) => ({
      checkout: state.checkout as Checkout,
      userInformation: state.user?.information as UserInformation,
      delivery: (state.fulfillment as Fulfilment)?.delivery
    })
  );

  useEffect(() => {
    if (delivery?.contactUpdated && typeof callback === 'function') {
      callback();
    }
  }, [delivery?.contactUpdated]);

  useEffect(() => {
    if (
      userInformation?.clientContext?.contact &&
      !userInformation.clientContext.contact?.email
    ) {
      setShowContactModal(true);
    }
  }, [userInformation]);

  const ChangeContactModal = () => {
    if (!showContactModal) {
      return null;
    }
    const isOnePass = !!userInformation?.userDetails?.loginId;
    const personalInfoData = getPersonalInfo({
      userContact: userInformation?.clientContext?.contact,
      flow: checkout.checkoutFlow,
      isOnePass,
      customAction: {
        type: ActionTypes.CHANGE_PERSONAL_INFO_EXISTING,
        text: t('CHANGE') as string
      }
    });
    return (
      <ChangeContactDetails
        title={t('VERIFY_EMAIL_TITLE') as string}
        headerMessage={t('VERIFY_EMAIL_MESSAGE') as string}
        isOpen={showContactModal}
        disableClose={true}
        fields={[ChangeContactField.HEADER_TITLE, ChangeContactField.EMAIL]}
        closeContactModal={() => {
          setShowContactModal(false);
        }}
        personalInfo={personalInfoData as any}
      />
    );
  };

  return { ChangeContactModal };
};
