/**
 * string to clean up as slug
 * function will replace spaces and special characters with -
 * function will replace + as -plus word
 *
 * @param {String} str
 *
 * Credits: Taken from Unmanned Store
 */
const slugify = str => {
  return (
    str &&
    str
      .trim()
      .toLowerCase()
      .replace(/\s?\+/g, '-plus')
      .replace(/[^A-Za-z0-9]/g, '-')
  );
};

/**
 * USING `module.exports` on-purpose. this function is also used in
 * gatsby-node to generate the slugs for sitemap
 */

module.exports = slugify;
