import { BulletList, Spacing, Text, TextLink } from '@dls/web';
import React from 'react';
import { Cart as CartIcon } from '@dls/assets/dist/icons/web';
import { Inline } from './styles';
import { RRP_INSTALLMENT_PLANT } from '../../config/rrp';

export const IppEligibilityLiner = () => {
  return (
    <Spacing top={1} responsive={false}>
      <Spacing bottom={'4px'}>
        <Text type="boldBody">
          Singtel PayLater instalment payment plan is available for Singtel
          customers who:
        </Text>
      </Spacing>
      <BulletList>
        <Text type="body">
          Are Singapore Citizens (SC), Permanent Residents (PR) and Foreigner
          Employment Pass (EP) holders aged 21 years and above
        </Text>
      </BulletList>
      <BulletList>
        <Text type="body">
          Own Singtel Mobile service(s) and is limited to only 3{' '}
          <TextLink
            data-testid="ipp-eligibility-liner-rrp-link"
            onClick={() => {
              window.open(RRP_INSTALLMENT_PLANT, '__blank');
            }}
          >
            instalment payment
          </TextLink>{' '}
          plans per NRIC
        </Text>
      </BulletList>
      <BulletList>
        <Text type="body">
          Have a good credit score with no late payment history in the last 6
          months
        </Text>
      </BulletList>
      <BulletList>
        <Text type="body">
          PayLater 0% interest instalment payment plans of up to 36 mths
        </Text>
      </BulletList>
      <Spacing bottom={'4px'} top={1} responsive={false}>
        <Inline>
          <Spacing responsive={false} right={'6px'}>
            <CartIcon size={24} />
          </Spacing>
          <Text type="boldBody">
            You may only checkout 1 item with Singtel PayLater instalment
            payment plan
          </Text>
        </Inline>
      </Spacing>
    </Spacing>
  );
};
