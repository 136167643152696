import {
  Contact,
  LineOfBusiness,
  UserInformation
} from '../../types/user.types';
import {
  ActionTypes,
  Contract,
  PaymentType,
  ProductOrder,
  QuoteData,
  PromoCodeInfo,
  SectionAction,
  SectionContent,
  SelectedBillingOptions,
  PromoCodeEmpty,
  EAppointmentData,
  MembershipInfo,
  FormattedPaymentOption,
  GetPaymentOptionsParams,
  IsPaymentEnabledParams,
  PaymentRequest,
  PaymentContextDetails,
  ShowBillingCheck
} from '../../types/orderSummary.types';
import {
  formatContactNumber,
  getFormattedDisplayAddress,
  maskString
} from '../../helpers/common';
import { KeyValue } from '../../types/common.types';
import {
  BaseAddress,
  BillingAccountDetail,
  ContactAddress,
  FieldObject
} from '../../types/registrationCheckout';
import CONSTANTS from '../../constants/common';
import translate from '../../hooks/useTranslation';
import CHECKOUT_CONSTANTS from '../../constants/checkout';
import FULFILMENT_CONSTANTS from '../../constants/fulfillment';
import { USER as USER_CONSTANTS } from '../../constants/user';
import ORDER_CONSTANTS from '../../constants/order';
import CART_CONSTANTS from '../../constants/cart';
import { formatDate } from '../Fulfilment/helpers';
import {
  CartItems,
  CartOrder,
  SelectedPlan
} from '../../types/shoppingCart.types';
import { config } from '@detox/actions';
import { SkuVariant } from '../../types/product.types';
import isSimOnlyPlan from '../../helpers/is-sim-only-plan';
import isMobileSharePlan from '../../helpers/is-mobile-share-plan';
import { Checkout } from '../../types/checkout.types';
import { Accessory } from '../../types/order';
import { Headers } from '../../types/auth.types';
import { parse } from 'date-fns';
import { IphoneState } from '../../reducers/iPhone';
import {
  CCE_IPHONE_FLOW,
  UPDATE_PARTIAL_CONTACT
} from '../../types/featureFlag.types';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { ORDER_SUMMARY_CONSTANTS } from '../../constants/ordersummary';

const composeContent = (data: KeyValue) => {
  return Object.entries(data).reduce((result, [key, value]) => {
    if (value) {
      result.push(value);
    }
    return result;
  }, []);
};

const formatDOBFunc = dob => {
  let formatDOB;
  if (!dob) return null;
  if (!isNaN(+new Date(dob))) {
    formatDOB = new Date(dob);
  } else {
    formatDOB = parse(dob?.replaceAll(' ', ''), 'dd/MM/yyyy', new Date());
  }
  return formatDOB;
};

export const getUserContactInfo = (
  userContact: KeyValue,
  updatedContact: KeyValue
): KeyValue => {
  const { emailId: email, phone } = updatedContact || {};

  if (email && phone) {
    return { ...userContact, email, phone };
  }

  return userContact;
};

export const getPersonalInfo = ({
  userContact,
  needMask,
  flow,
  isOnePass,
  customAction
}: {
  userContact: Contact;
  needMask?: boolean;
  flow: string;
  isOnePass: boolean;
  customAction?: SectionAction;
}): SectionContent | KeyValue => {
  const { t } = translate();
  const formatDOB = formatDOBFunc(userContact?.dateOfBirth);

  const isNewNew = flow === CHECKOUT_CONSTANTS.CHECKOUT_NEW_NEW_CUSTOMER;
  const isRecon = flow === CHECKOUT_CONSTANTS.CHECKOUT_RECON;
  const dob = isNewNew
    ? userContact.dateOfBirth
    : isOnePass
    ? formatDate(formatDOB, 'dd MMM yyyy')
    : '';
  const info = userContact
    ? {
        name: userContact.registeredName || userContact.firstName,
        nricFin: needMask
          ? maskString(userContact.indentValue)
          : userContact.indentValue,

        dob,
        phone: needMask
          ? maskString(userContact.phone)
          : formatContactNumber(userContact.phone),
        email: needMask ? maskString(userContact.email) : userContact.email
      }
    : {};
  const changePersonalForOnepass =
    isOnePass && isFeatureFlagEnabled(UPDATE_PARTIAL_CONTACT)
      ? {
          type: isRecon
            ? ActionTypes.CHANGE_PERSONAL_INFO_RECON
            : ActionTypes.CHANGE_PERSONAL_INFO_EXISTING,
          text: t('CHANGE')
        }
      : null;
  const action: SectionAction | KeyValue =
    customAction ||
    (isNewNew
      ? {
          type: ActionTypes.CHANGE_PERSONAL_INFO,
          text: t('CHANGE')
        }
      : changePersonalForOnepass);
  return {
    content: composeContent(info),
    ...(action ? { action } : {})
  };
};

export const getESimDeliveryOptions = (
  isESimOrder: boolean,
  checkoutFormData: FieldObject
): FieldObject => {
  if (!isESimOrder) {
    return null;
  }
  const { t } = translate();
  return (
    checkoutFormData && {
      title: t('E_SIM_DELIVERY_TITLE'),
      content: [checkoutFormData]
    }
  );
};

export const getBillingAddressInfo = (
  contactAddress: BaseAddress,
  flow: string,
  hideBillingChange = false
): SectionContent => {
  const { t } = translate();
  const isRecon = flow === CHECKOUT_CONSTANTS.CHECKOUT_RECON;
  const info = contactAddress
    ? {
        address: getFormattedDisplayAddress(contactAddress),
        city: `${CONSTANTS.SINGAPORE} ${contactAddress?.postcode}`
      }
    : {};
  const content = composeContent(info);
  let action: SectionAction = null;
  if (isRecon) {
    content.push({
      text: t('ORDER_SUMMARY_CHANGE_BILLING_LINER'),
      style: 'smallBody'
    });
  } else {
    action = hideBillingChange
      ? null
      : {
          type: ActionTypes.CHANGE_BILLING_ADDRESS,
          text: t('CHANGE')
        };
  }
  return {
    content: content,
    action
  };
};

const getCustomerDetailsRequestData = ({
  userInformation,
  authHeaders,
  billPayer
}: {
  userInformation: UserInformation;
  authHeaders: Headers;
  billPayer: string | number;
}) => {
  const { contact = {}, subscriptions = [] } =
    userInformation?.clientContext || {};
  const mobileSubscriber = subscriptions.some(
    subscription => subscription.lineOfBusiness === LineOfBusiness.MOBILE
  );

  const tvSubscriber = subscriptions.some(
    subscription => subscription.lineOfBusiness === LineOfBusiness.TV
  );

  const broadbandSubscriber = subscriptions.some(
    subscription =>
      subscription.lineOfBusiness === LineOfBusiness.FIBRE_BROADBAND
  );
  const { indentType, indentValue, email, lastName, phone } = contact;
  const isWorryFreeLogin =
    !userInformation.anonymousUser && userInformation.otpUser;
  return JSON.stringify({
    userId: '', // will check it later.
    nickName: 'anonymous',
    nric: indentValue,
    customerIdType: indentType,
    email,
    familyName: lastName,
    newcustomer: userInformation.anonymousUser,
    mobile: phone,
    broadband: broadbandSubscriber,
    tv: tvSubscriber,
    mobileSubscriber,
    isWorryFreeLogin,
    isWorryFreeFaVerfied:
      isWorryFreeLogin && authHeaders.WORRY_FREE_FA_CHECK_REQD === 'Y',
    billPayer: billPayer || userInformation.userDetails?.billPayer,
    filters: null // no used
  });
};

const getOrderDetailsRequestData = (params: Partial<PaymentParams>) => {
  const {
    barId,
    productOrder,
    customerId,
    paymentType,
    cartItems,
    contractList = [],
    planOnly
  } = params;
  const {
    productOrderId,
    productOrderReferenceNumber,
    productOrderItemId,
    productId
  } = productOrder;

  return JSON.stringify({
    isDetoxOrder: true,
    planOnly,
    productOrderItemID: productOrderItemId,
    productID: productId,
    productOrderID: productOrderId,
    productOrderReferenceNumber,
    methodType: paymentType === PaymentType.ONLINE ? 'onePay' : 'NA',
    barId,
    orderActions: getOrderActions({ ...params, planOnly }),
    customerID: customerId,
    updatedOrderActions: [productOrderItemId],
    currentOrderActions: getOrderActions({ ...params, planOnly }, true),
    contractList: contractList.map(contract => {
      return {
        documentID: contract.documentId
      };
    }),
    promoOrderActions: [productOrderItemId],
    silverComponentAdded: productOrder?.hasSilverComponent,
    accessoriesAdded: getAccessoryIds(cartItems.accessories),

    //
    accountId: null,
    orderId: productOrderId,
    isChangeUserScenario: false, // not used
    isOrderAccepted: null, // not used for Detox
    orderSubmissionStatus: null, // not used
    emailNotificationRequired: null, // not used
    orderMapped: null, // not used
    deliverAddress: null, // not used
    billingAddress: null, // not used
    tvSubscriptionFlag: null, // not required,
    mobileFlow: null,
    contnueShoppingFlag: null,
    freebiesFailed: null
  });
};

const getAccessoryIds = (accessories: Accessory[] = []) => {
  return accessories?.map(accessory => accessory.productID);
};

const getOrderActions = (
  {
    productOrder,
    cartOrder = {},
    cartItems = {},
    quoteData = {},
    selectedPlan,
    selectedProduct,
    lineOfBusiness,
    planOnly,
    ...rest
  }: Partial<PaymentParams>,
  isCurrentOrderActions = false
) => {
  const { productOrderItemId, productId } = productOrder;
  const { accessories } = cartItems;
  const { productSpecificationLevel } = quoteData;
  const freebiesIds = getValidFreebieIds({ ...rest, cartItems });
  const accessoriesIds = getAccessoryIds(accessories);
  const orderType =
    productOrder.type === ORDER_CONSTANTS.TYPE.RECON
      ? ORDER_CONSTANTS.ORDER_TYPE.CHANGE
      : ORDER_CONSTANTS.ORDER_TYPE.PROVIDE;
  let flowType;
  const isSimOnly = isSimOnlyPlan(selectedPlan);
  const isMobileShareNew = productOrder.type === ORDER_CONSTANTS.TYPE.MS_NEW;
  switch (true) {
    case isMobileShareNew || isMobileSharePlan(selectedPlan): {
      flowType = CART_CONSTANTS.FLOW_TYPES.MOBILE_SHARE_PLAN;
      break;
    }
    case isSimOnly && !selectedProduct?.sku: {
      flowType = CART_CONSTANTS.FLOW_TYPES.SIM_ONLY_PLAN;
      break;
    }
    default: {
      flowType = CART_CONSTANTS.FLOW_TYPES.DEVICE_FIRST;
    }
  }
  return [
    {
      productOrderItemID: productOrderItemId,
      productID: productId,
      level: productSpecificationLevel,
      lob: lineOfBusiness,
      flowType: flowType,
      orderType,
      isPPOrder: !!(isSimOnly && selectedProduct?.sku),
      msisdn: cartItems.serviceId,
      simProductId: cartOrder?.newlyAddedSimDetails?.simProductId,
      simType: cartOrder?.newlyAddedSimDetails?.simDetails?.simType,
      deviceProductId: cartOrder?.mobile?.device?.deviceProductID,
      freebiesIds: isCurrentOrderActions ? undefined : freebiesIds,
      accessoriesIds: isCurrentOrderActions ? undefined : accessoriesIds,
      notFreeMBB: isCurrentOrderActions ? undefined : true,
      planOnly
    }
  ];
};

const getSelectedBillOptions = (
  billingAccountDetails: BillingAccountDetail[] = [],
  checkout: Checkout = {},
  userInformation: UserInformation = {}
): SelectedBillingOptions => {
  const { contact = {}, billingArrangements = [] } =
    userInformation?.clientContext || {};
  const { checkoutFormData = {}, createdAccountId: createdAccount } = checkout;
  const billingPreference = checkoutFormData?.billingPreference || 0;
  const billingAddress = checkoutFormData?.billingAddress || 0;
  const getSelectedBillOptionsFromBillingDetails = (
    selectedBillingDetails: BillingAccountDetail
  ) => {
    const { indentValue, contactId, indentType } = contact;
    return {
      type: USER_CONSTANTS.IDS[indentType],
      contactId,
      nric: indentValue,
      skipNotification: false,
      deliveryMethod: selectedBillingDetails?.billDeliveryMethod,
      billType: selectedBillingDetails?.billType,
      intFlowType:
        selectedBillingDetails?.billType ===
        FULFILMENT_CONSTANTS.BILLING_TYPE.EBILL
          ? FULFILMENT_CONSTANTS.BILLING_FLOW_TYPE.EBILL
          : FULFILMENT_CONSTANTS.BILLING_FLOW_TYPE.PAPER_BILL
    };
  };
  switch (checkout.checkoutFlow) {
    case CHECKOUT_CONSTANTS.CHECKOUT_NEW_NEW_CUSTOMER: {
      return {
        type: USER_CONSTANTS.IDS[checkout.verificationData?.passType],
        contactId: createdAccount?.contactId,
        nric: checkout.verificationData?.id,
        skipNotification: false,
        intFlowType:
          billingPreference === 0
            ? FULFILMENT_CONSTANTS.BILLING_FLOW_TYPE.EBILL
            : FULFILMENT_CONSTANTS.BILLING_FLOW_TYPE.PAPER_BILL,
        deliveryMethod:
          billingPreference === 0
            ? FULFILMENT_CONSTANTS.BILLING_METHODS.NONE_PAPER
            : FULFILMENT_CONSTANTS.BILLING_METHODS.PAPER,
        billType:
          billingPreference === 0
            ? FULFILMENT_CONSTANTS.BILLING_TYPE.EBILL
            : FULFILMENT_CONSTANTS.BILLING_TYPE.PAPER_BILL
      };
    }
    case CHECKOUT_CONSTANTS.CHECKOUT_EXISTING_NEW_CUSTOMER: {
      const selectedBillingDetails = billingAccountDetails[billingAddress];
      return getSelectedBillOptionsFromBillingDetails(selectedBillingDetails);
    }
    case CHECKOUT_CONSTANTS.CHECKOUT_RECON: {
      const barId = billingArrangements[0]?.barId || checkout.billingAccountId;
      const selectedBillingDetails = billingAccountDetails.find(
        baDetails => baDetails.billingAccountNumber === barId
      );
      return getSelectedBillOptionsFromBillingDetails(selectedBillingDetails);
    }
  }
};

export const validatePaymentRequest = (
  paymentRequest: PaymentRequest
): boolean => {
  try {
    const paymentContextDetails = JSON.parse(
      paymentRequest.contextDetails
    ) as PaymentContextDetails;
    const {
      deviceProductId,
      accessoriesIds = [],
      freebiesIds = []
    } = paymentContextDetails.orderDetails[0];
    const productsInCart = [
      ...accessoriesIds,
      ...freebiesIds,
      ...(deviceProductId ? [deviceProductId] : [])
    ];
    return (
      productsInCart.length === paymentContextDetails.deviceResDetails.length
    );
  } catch (e) {
    return false;
  }
};

export const getDeviceResDetails = ({
  eAppointmentDealerCode,
  reservedDevice,
  productOrderItemId,
  isAllocate = true,
  reservedSim,
  productId = ''
}: {
  eAppointmentDealerCode?: string;
  reservedDevice: KeyValue;
  productOrderItemId: string;
  isAllocate?: boolean;
  reservedSim?: ReservationDevice;
  productId?: string;
}): KeyValue => {
  return {
    id: reservedDevice.type,
    storeID: eAppointmentDealerCode || config.dealerId,
    itemCodeX9: reservedDevice.itemCodeX9,
    orderActionIDX9: productOrderItemId,
    orderStoreId: eAppointmentDealerCode ? config.dealerId : '0',
    quantity: '1',
    reservationId: isAllocate
      ? reservedDevice.allocationIDX9
      : reservedDevice.reservationIDX9,
    ...(reservedSim
      ? {
          simItemCodeX9: reservedSim.itemCodeX9,
          simQuantity: '1',
          simReservationId: reservedSim.reservationIDX9
        }
      : {}),
    ...(productId ? { productId } : {})
  };
};

export const getStoreAppDetails = (
  isCollectAtStore: boolean,
  storeEAppData: EAppointmentData
): KeyValue => {
  if (!isCollectAtStore || !storeEAppData.storeAptDetails) return {};

  return {
    ...storeEAppData,
    storeAptDetails: {
      ...storeEAppData.storeAptDetails,
      apptState: storeEAppData.eApptState,
      apptId: storeEAppData.eApptId
    }
  };
};

const isReservedSim = (reservedDevice: ReservationDevice): boolean => {
  return ([config.simCard5GSku, config.simCardSku] as string[]).includes(
    reservedDevice.itemCodeX9
  );
};

const getValidFreebieIds = params => {
  const {
    allocationStatusArray = [],
    reservationStatusArray = [],
    deliveryMode,
    cartItems
  } = params || {};

  const isCollectAtStore =
    deliveryMode === FULFILMENT_CONSTANTS.FULFILMENT_TYPES.COLLECT_AT_STORE;
  const freebieIDs = [];

  if (!isCollectAtStore) {
    allocationStatusArray?.forEach(item => {
      const { itemCodeX9 } = item;
      const freebieProduct = cartItems?.freebies?.find(
        freebie => itemCodeX9 === freebie.skuId && !freebie.unavailable
      );

      if (freebieProduct) {
        freebieIDs.push(freebieProduct.productID);
      }
    });
  }

  if (isCollectAtStore) {
    reservationStatusArray.forEach(reservedDevice => {
      if (!isReservedSim(reservedDevice)) {
        const { itemCodeX9 } = reservedDevice;
        const freebieProduct = cartItems?.freebies?.find(
          freebie => itemCodeX9 === freebie.skuId
        );

        if (freebieProduct) {
          freebieIDs.push(freebieProduct.productID);
        }
      }
    });
  }

  return freebieIDs.filter(Boolean);
};

const getReservedProductID = (reservedDevice, params) => {
  if (!reservedDevice || !params) {
    return '';
  }

  const { itemCodeX9 } = reservedDevice;
  const { cartOrder, cartItems } = params;
  const accessoryProduct = cartItems?.accessories?.find(
    accessorie => itemCodeX9 === accessorie.skuId
  );
  const freebieProduct = cartItems?.freebies?.find(
    freebie => itemCodeX9 === freebie.skuId && !freebie.unavailable
  );

  if (itemCodeX9 === cartOrder?.mobile?.device?.sku) {
    return cartOrder?.mobile?.device?.deviceProductID;
  } else if (accessoryProduct) {
    return accessoryProduct.productID;
  } else if (freebieProduct) {
    return freebieProduct.productID;
  }

  return '';
};

const getPaymentContextDetails = (params: Partial<PaymentParams>) => {
  const {
    userInformation,
    productOrder,
    customerId,
    allocationStatusArray = [],
    reservationStatusArray = [],
    deliveryMode,
    barId,
    billingAccountDetails,
    checkout,
    paymentType,
    billPayer,
    planOnly,
    storeEAppData,
    iphone,
    isRrpIppOrder = false
  } = params;
  let roiDetails: RoiDetails = null;
  const { contact = {} } = userInformation?.clientContext || {};
  const { contactId, indentValue } = contact;
  const {
    productOrderId,
    productOrderReferenceNumber,
    productOrderItemId
  } = productOrder;
  const deviceResDetails = [];
  const allocationIDs = [],
    reservationIDs = [];
  const {
    dealerCode: eAppointmentDealerCode,
    ...appointmentData
  } = storeEAppData || { dealerCode: undefined };
  const isCollectAtStore =
    deliveryMode === FULFILMENT_CONSTANTS.FULFILMENT_TYPES.COLLECT_AT_STORE;

  allocationStatusArray?.forEach(item => {
    allocationIDs.push(item.allocationIDX9);
    if (!isCollectAtStore) {
      const productIdForReserved = getReservedProductID(item, params);

      if (productIdForReserved) {
        deviceResDetails.push(
          getDeviceResDetails({
            reservedDevice: item,
            productOrderItemId,
            productId: productIdForReserved
          })
        );
      }
    }
  });

  if (isCollectAtStore) {
    const reservedSim = reservationStatusArray.find(reservedDevice =>
      isReservedSim(reservedDevice)
    );
    reservationStatusArray.forEach(reservedDevice => {
      reservationIDs.push(reservedDevice.reservationIDX9);
      if (!isReservedSim(reservedDevice)) {
        const productIdForReserved = getReservedProductID(
          reservedDevice,
          params
        );
        if (productIdForReserved) {
          deviceResDetails.push(
            getDeviceResDetails({
              eAppointmentDealerCode,
              reservedDevice,
              productOrderItemId,
              isAllocate: false,
              reservedSim,
              productId: productIdForReserved
            })
          );
        }
      }
    });
  }

  if (iphone.roiToken && iphone.validateSkuData) {
    const { roiToken, groupCampaignId, validateSkuData, formData } = iphone;
    const nric = validateSkuData?.custId || formData?.nric;

    roiDetails = {
      campaignId: groupCampaignId,
      token: roiToken,
      nric
    };
  }

  const isCCEEnabled = isFeatureFlagEnabled(CCE_IPHONE_FLOW) || false;
  const roiDetailsData = isCCEEnabled && roiDetails ? { roiDetails } : {};

  return JSON.stringify({
    methodType: 'NA',
    customerID: customerId,
    orderId: productOrderId,
    orderDetails: getOrderActions({ ...params, planOnly }),
    productOrderReferenceNumber,
    contactId: contactId,
    barId: barId,
    deviceResDetails,
    selectedOption:
      FULFILMENT_CONSTANTS.MAPPED_FULFILMENT_TYPES[deliveryMode] || '',
    allocationIDs,
    reservationIDs,
    paymentMode: paymentType,
    selectedBillOption: getSelectedBillOptions(
      billingAccountDetails,
      checkout,
      userInformation
    ),
    nric: indentValue,

    billPayer: billPayer || userInformation?.userDetails?.billPayer,
    ...getStoreAppDetails(
      isCollectAtStore,
      appointmentData as EAppointmentData
    ),
    dwfmTokenId: null, // not required.
    collectNowData: null, // not required for now, may need to implement in future.
    smsNotification: [], // used only for fiber flow
    emailNotification: [], // used only for fiber flow
    isRrpIpp: isRrpIppOrder,
    dashPayment: false, // get value from dash popup, Check user exist = 0 -> show popup.
    ...roiDetailsData // roi data
  });
};

export type ReservationDevice = {
  reservationOKX9: boolean;
  itemCodeX9: string;
  qtyX9: number;
  statusMSGX9: string;
  reservationIDX9: string;
  reservationTypeX9: string;
};

interface RoiDetails {
  campaignId: string;
  nric: string;
  token: string;
}

interface PaymentParams {
  quoteData: QuoteData;
  userInformation: UserInformation;
  customerId: string;
  productOrder: ProductOrder;
  cartOrder: CartOrder;
  cartItems: CartItems;
  deliveryMode: string;
  allocationStatusArray: KeyValue[];
  reservationStatusArray?: ReservationDevice[];
  barId?: string;
  checkoutBarId?: string;
  selectedPlan: SelectedPlan;
  selectedProduct: SkuVariant;
  billingAccountDetails: BillingAccountDetail[];
  checkout: Checkout;
  paymentType: PaymentType;
  contractList: Contract[];
  authHeaders: Headers;
  merchantCode: string;
  lineOfBusiness: string;
  billPayer: string | number;
  planOnly?: boolean;
  storeEAppData?: EAppointmentData;
  iphone?: IphoneState;
  isRrpIppOrder?: boolean;
}

export const getPaymentRequestParams = (
  paymentParams: PaymentParams
): PaymentRequest => {
  const {
    quoteData = {},
    productOrder = {},
    userInformation = {},
    authHeaders,
    merchantCode,
    billPayer,
    barId,
    selectedPlan,
    selectedProduct,
    iphone,
    paymentType
  } = paymentParams;
  const { checkOutPriceBreakdown } = quoteData;
  const { contact = {} } = userInformation?.clientContext || {};
  const { productOrderId, productOrderReferenceNumber } = productOrder;
  const planOnly =
    productOrder.type === ORDER_CONSTANTS.TYPE.MS_NEW ||
    (isSimOnlyPlan(selectedPlan) && !(selectedProduct && selectedProduct.sku));
  return {
    orderId: productOrderId,
    referenceId: '', // refId will be fetched in actions
    paymentAmount: checkOutPriceBreakdown?.finalAmountIncludingTax,
    paymentStatus: 'PENDING',
    paymentMethod: paymentType === PaymentType.ONLINE ? '-' : 'NA',
    account: barId,
    email: contact.email,
    customerName: contact.registeredName,
    customerDetails: getCustomerDetailsRequestData({
      userInformation,
      authHeaders,
      billPayer
    }),
    orderDetails: getOrderDetailsRequestData({
      ...paymentParams,
      planOnly
    }),
    contextDetails: getPaymentContextDetails({
      ...paymentParams,
      planOnly,
      iphone
    }),
    merchantCode,
    comments: `Payment initiated for order ${productOrderReferenceNumber}`,
    currency: 'SGD'
  };
};

export const getPaymentIntentParams = ({
  userInformation,
  quoteData,
  contactAddress = {} as ContactAddress,
  customerId,
  productOrderReferenceNumber
}: {
  userInformation: UserInformation;
  quoteData: QuoteData;
  contactAddress: ContactAddress;
  customerId: string;
  productOrderReferenceNumber: string;
}): KeyValue => {
  const { checkOutPriceBreakdown } = quoteData;
  const { finalAmountIncludingTax, finalTaxAmount, finalAmount } =
    checkOutPriceBreakdown || {};
  const userContact = userInformation?.clientContext?.contact || {};
  const { registeredName, phone, email } = userContact;
  const contactNumber = phone.replace(/\+[0-9]{1,}/, '').replace(/ +/g, '');

  return {
    merchant_reference_number: '', // will pass this field in action, in getRef api.
    amount: finalAmountIncludingTax,
    currency: 'SGD',
    customer_id: customerId,
    email,
    mobile_number: contactNumber,
    merchant_data: JSON.stringify({
      contactId: userContact.contactId,
      accounts: [
        {
          faid: userInformation?.clientContext?.financialAccounts?.[0]?.faId,
          amountToPay: finalAmountIncludingTax,
          totalDue: finalAmountIncludingTax,
          customerId: customerId
        }
      ]
    }),
    customer_Info: {
      cust_name: registeredName,
      mobile_number: contactNumber,
      postal_code: contactAddress.postcode,
      billing_address: `${contactAddress.apartment} ${CONSTANTS.SINGAPORE} ${contactAddress.postcode}`
    },
    cart_details: {
      total_amount_bef_gst: finalAmount,
      gst_amount: finalTaxAmount,
      total_amount_inc_gst: finalAmountIncludingTax,
      cart_items: [
        {
          order_number: productOrderReferenceNumber,
          device_description: `Singtelshop.com purchase for ${productOrderReferenceNumber}`,
          device_amount: finalAmountIncludingTax
        }
      ]
    }
  };
};

export const getPromoResult = (
  promoCodeInfo: PromoCodeInfo,
  orderSummary: KeyValue
): KeyValue => {
  const { t } = translate();
  if (!promoCodeInfo) {
    return null;
  }

  const promoText = orderSummary?.quoteData?.appliedPromoText?.value || '';
  const { errorMessage, isValid, promoCode, isLoading } = promoCodeInfo;
  const isPromoEmpty =
    promoCode === PromoCodeEmpty.EMPTY || promoCode === PromoCodeEmpty.CLEAR;
  const validatedPromo = isPromoEmpty ? '' : promoCode;
  const isSuccess = isValid && !isPromoEmpty && promoText;
  const keepPromoOpen = isValid || errorMessage ? { keepPromoOpen: true } : {};
  let errorMessageText: string;

  if (isValid && !promoText && !isPromoEmpty) {
    errorMessageText = t('PROMO_INVALID_THIS_ORDER');
  } else if (errorMessage) {
    errorMessageText = t('PROMO_INVALID');
  }

  let formattedPromoText: string;
  if (
    errorMessage === ORDER_SUMMARY_CONSTANTS.INVALID_PROMO_CODE ||
    isLoading
  ) {
    formattedPromoText = undefined;
  } else {
    formattedPromoText = promoText.replace('*@@@', '');
  }

  const promoCodeResult = {
    isLoading,
    errorMessage: errorMessageText,
    appliedPromoCode: validatedPromo,
    isSuccess,
    promoText: formattedPromoText,
    ...keepPromoOpen
  };

  return { promoCodeResult };
};

export const isZeroUpfrontCharges = (quoteData: KeyValue): boolean => {
  return (
    parseFloat(quoteData?.checkOutPriceBreakdown?.finalAmountIncludingTax) === 0
  );
};

export const getYuuMemberInfo = (
  aemMembershipData: MembershipInfo[] = [],
  partnerId: string
): MembershipInfo => {
  const bannerData = aemMembershipData.find(
    data => data.member === Boolean(partnerId)
  );

  return {
    value: partnerId || null,
    title: bannerData?.title,
    description: partnerId || bannerData?.description,
    footer: bannerData?.footer,
    icon: bannerData?.icon,
    member: bannerData?.member
  };
};

export const getFreebieStockData = (freebies: KeyValue[]): KeyValue[] => {
  if (!freebies) {
    return [];
  }

  return freebies.map(freebie => {
    return {
      itemCode: freebie.skuId,
      status: freebie.unavailable ? 'NA' : 'AL',
      availableQty: freebie.unavailable ? 0 : 1
    };
  });
};

const mapPaymentOptionData = (
  aemPaymentType: string
): { icon: string; type: string } => {
  switch (aemPaymentType) {
    case ORDER_CONSTANTS.AEM_PAYMENT_TYPES.CASH: {
      return {
        icon: '',
        type: ORDER_CONSTANTS.PAYMENT_TYPES.CASH
      };
    }
    default: {
      return {
        icon: '',
        type: ORDER_CONSTANTS.PAYMENT_TYPES.ONLINE
      };
    }
  }
};

const isPaymentOptionEnabled = ({
  paymentOption,
  deliveryMethod,
  flow,
  isExistingCustomer
}: IsPaymentEnabledParams): boolean => {
  let isEnabledForDeliveryMethod = false,
    isEnabledForFlow = false;
  switch (deliveryMethod) {
    case FULFILMENT_CONSTANTS.FULFILMENT_TYPES.COLLECT_AT_STORE: {
      isEnabledForDeliveryMethod = paymentOption.deliveryOption.storePickUp;
      break;
    }
    case FULFILMENT_CONSTANTS.FULFILMENT_TYPES.SNAIL_MAIL: {
      isEnabledForDeliveryMethod = paymentOption.deliveryOption.snailMail;
      break;
    }
    case FULFILMENT_CONSTANTS.FULFILMENT_TYPES.DOOR_STEP_DELIVERY: {
      isEnabledForDeliveryMethod = paymentOption.deliveryOption.courier;
      break;
    }
    case FULFILMENT_CONSTANTS.FULFILMENT_TYPES.POP_STATION: {
      isEnabledForDeliveryMethod = paymentOption.deliveryOption.popStation;
      break;
    }
  }

  switch (true) {
    case flow === ORDER_CONSTANTS.TYPE.NEW && !isExistingCustomer: {
      isEnabledForFlow = paymentOption.transactionType.newNewNumber;
      break;
    }
    case flow === ORDER_CONSTANTS.TYPE.NEW && isExistingCustomer: {
      isEnabledForFlow = paymentOption.transactionType.existingNewNumber;
      break;
    }
    case flow === ORDER_CONSTANTS.TYPE.PORTIN && !isExistingCustomer: {
      isEnabledForFlow = paymentOption.transactionType.newNewPortin;
      break;
    }
    case flow === ORDER_CONSTANTS.TYPE.PORTIN && isExistingCustomer: {
      isEnabledForFlow = paymentOption.transactionType.existingNewPortin;
      break;
    }
    case flow === ORDER_CONSTANTS.TYPE.RECON: {
      isEnabledForFlow = paymentOption.transactionType.recon;
      break;
    }
  }
  return isEnabledForFlow && isEnabledForDeliveryMethod;
};

export const getPaymentOptions = ({
  paymentOptions,
  deliveryMethod,
  flow,
  isExistingCustomer
}: GetPaymentOptionsParams): FormattedPaymentOption[] => {
  return paymentOptions.reduce(
    (result: FormattedPaymentOption[], paymentOption) => {
      if (
        isPaymentOptionEnabled({
          paymentOption,
          deliveryMethod,
          flow,
          isExistingCustomer
        })
      ) {
        result.push({
          title: paymentOption.paymentTitle,
          description: paymentOption.paymentDesc,
          ...mapPaymentOptionData(paymentOption.paymentMethodKey)
        });
      }
      return result;
    },
    []
  );
};

export const getShowBillingChangeState = ({
  isESimOrder,
  isNewNewFlow,
  userCISMyInfoAvailable,
  isCompleteMyInfoData
}: ShowBillingCheck): boolean => {
  const isRESeSIM = isESimOrder && isNewNewFlow;
  const isRESpSIMWithCompleteData =
    !isESimOrder && isNewNewFlow && isCompleteMyInfoData;

  return userCISMyInfoAvailable || isRESeSIM || isRESpSIMWithCompleteData;
};
