import { ACTION_TYPES } from '../constants/actions';
import { KeyValue, SpecialPromo } from '../types/common.types';
interface PromotionState {
  specialPromo?: SpecialPromo;
  promoOrderDetails?: KeyValue;
}
export const initialState: PromotionState = {
  specialPromo: null
};

export const promotionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.PROMOTION.SAVE_PROMOTION_ORDER_DETAILS: {
      return {
        ...state,
        promoOrderDetails: action.value
      };
    }
    case ACTION_TYPES.PROMOTION.SET_SPECIAL_PROMOTION: {
      return {
        ...state,
        specialPromo: action.value
      };
    }

    case ACTION_TYPES.PROMOTION.CLEAR_SPECIAL_PROMOTION: {
      return {
        ...state,
        specialPromo: null
      };
    }
    default: {
      return state;
    }
  }
};

export const setSpecialPromo = (specialPromo: SpecialPromo) => dispatch => {
  dispatch({
    type: ACTION_TYPES.PROMOTION.SET_SPECIAL_PROMOTION,
    value: specialPromo
  });
};

export const clearSpecialPromo = () => dispatch => {
  dispatch({
    type: ACTION_TYPES.PROMOTION.CLEAR_SPECIAL_PROMOTION
  });
};

export default promotionsReducer;
