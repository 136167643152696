import React, { ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, Spacing, Notification, Text } from '@dls/web';
import { Form } from '@wec-core/form-engine';
import { checkoutActions } from '@detox/actions';
import useTranslate from '../../../hooks/useTranslation';

import { KeyValue } from '../../../types/common.types';
import { getFormDataMapping } from '../../Checkout/helper';
import {
  compareAndGetContactDetails,
  confirmBtn,
  footerTitle,
  getContactGroup,
  getDOB,
  getEmailAndPhone,
  getFullName,
  getHeaderMessage,
  getNRIC
} from './helper';
import LoadingOverlayComponent from '../../LoadingOverlay';
import { ChangeContactField } from '../../../types/user.types';

interface PersonalInfo {
  action: KeyValue;
  content?: string[];
}

interface TProps {
  isOpen: boolean;
  closeContactModal: (callContract: boolean) => void;
  personalInfo: PersonalInfo;
  fields?: ChangeContactField[];
  headerMessage?: string;
  title?: string;
  disableClose?: boolean;
}

const ChangeContactDetails: React.FC<TProps> = ({
  isOpen,
  closeContactModal,
  personalInfo = {},
  headerMessage,
  title,
  disableClose,
  fields = [
    ChangeContactField.FULL_NAME,
    ChangeContactField.NRIC,
    ChangeContactField.DOB,
    ChangeContactField.FOOTER_TITLE,
    ChangeContactField.CONTACT_NUMBER,
    ChangeContactField.EMAIL
  ]
}): ReactElement => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const {
    isContactError = false,
    isContactUpdating = false,
    userInformation,
    checkout
  } = useSelector((state: KeyValue) => ({
    isContactError: state.orderSummary?.isContactError,
    isContactUpdating: state.orderSummary?.isContactUpdating,
    userInformation: state.user?.information,
    checkout: state.checkout
  }));
  const { createdAccountId, identityCheck } = checkout;
  const [name, nric, dob, phone, emailId] = personalInfo?.content || [];

  const hasField = (
    fieldName: ChangeContactField,
    fields: ChangeContactField[]
  ) => {
    return fields.includes(fieldName);
  };

  const formFieldsData = {
    fullName:
      hasField(ChangeContactField.FULL_NAME, fields) && name
        ? getFullName(name)
        : null,
    nric:
      hasField(ChangeContactField.NRIC, fields) && nric ? getNRIC(nric) : null,
    dob: hasField(ChangeContactField.DOB, fields) && dob ? getDOB(dob) : null,
    headerTitle:
      hasField(ChangeContactField.HEADER_TITLE, fields) &&
      headerMessage &&
      getHeaderMessage(headerMessage),
    contactGroup: getContactGroup(fields),
    footerTitle:
      hasField(ChangeContactField.FOOTER_TITLE, fields) && footerTitle,
    submitBtn: confirmBtn
  };

  const initialValues = {
    contactNumber: phone,
    email: emailId
  };

  const modalConfig = {
    visible: isOpen,
    onClose: () => closeContactModal(false),
    bgColor: 'white',
    backdropClosable: false,
    closable: !disableClose
  };

  const updateContact = async (details: KeyValue = {}) => {
    const userContactId =
      userInformation?.clientContext?.contact?.contactId ||
      createdAccountId?.contactId ||
      identityCheck?.contactId;

    const contactData = compareAndGetContactDetails(
      getEmailAndPhone(personalInfo),
      details
    );

    if (contactData) {
      const response: KeyValue = await dispatch(
        checkoutActions.updateCustomerContact({
          ...contactData,
          contactId: userContactId
        })
      );
      if (response?.implContactPerson) {
        closeContactModal(true);
      }
    } else {
      closeContactModal(false);
    }
  };

  const _onSubmit = values => {
    updateContact(values);
  };

  const formattedFormFieldsData = getFormDataMapping(formFieldsData);

  return (
    <Modal title={title || t('PERSONAL_DETAILS_TITLE')} {...modalConfig}>
      <Modal.Content>
        {isContactError && (
          <Notification
            data-testid="update-failed"
            type="alert"
            title={''}
            content={
              <>
                <Text>{t('PERSONAL_DETAILS_UPDATE_ERROR')}</Text>
              </>
            }
          />
        )}
        {formattedFormFieldsData && formattedFormFieldsData.length > 0 ? (
          <Spacing top={2}>
            <Form
              stackSpacing={2}
              enableReinitialize={true}
              formFieldsConfig={formattedFormFieldsData}
              initialValues={initialValues}
              data={{}}
              callbacks={{}}
              onSubmit={_onSubmit}
            />
          </Spacing>
        ) : null}

        {isContactUpdating && <LoadingOverlayComponent />}
      </Modal.Content>
    </Modal>
  );
};

export default ChangeContactDetails;
