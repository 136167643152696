import { createSelector } from 'reselect';
import { createTransform } from 'redux-persist';
import {
  CIS_GET_MYINFO_LOADING,
  ACTION_TYPES as API_ACTION_TYPES
} from '@detox/actions';
import { ACTION_TYPES } from '../constants/actions';

const initialState = { selection: {}, cis: {} };

export const userInformation = state => state.user?.information;

export const userContact = createSelector(
  [userInformation],
  information => information?.clientContext?.contact
);

export const isRedMember = createSelector(
  [userContact],
  contact => contact?.redTag === 'Y'
);

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.USER.SET_USER_SELECTION: {
      return {
        ...state,
        selection: action.payload
      };
    }
    case ACTION_TYPES.USER.RESET_USER: {
      // retain loading state to prevent loading reset
      const loadingState = 'loading' in state ? { loading: state.loading } : {};
      // reset everything except for cis
      return {
        cis: state.cis,
        // Shouldn't clear the plan that user selected in product details.
        selection: state.selection,
        ...loadingState
      };
    }
    case ACTION_TYPES.USER.RESET_USER_FOR_CHECKOUT: {
      // for checkout need to keep the selected product when browse back.
      return {
        cis: state.cis,
        selection: state.selection,
        mainLines: state.mainLines,
        products: state.products,
        selectedService: state.selectedService
      };
    }
    case ACTION_TYPES.USER.SET_USER: {
      return {
        ...state,
        ...action.value
      };
    }
    case ACTION_TYPES.USER.SET_USER_INFORMATION: {
      return {
        ...state,
        information: action.value,
        loading: false
      };
    }

    case ACTION_TYPES.USER.UPDATE_CUSTOMER_SUCCESS: {
      if (state.information?.clientContext || !action.value?.deliveryDetails) {
        return state;
      }
      const { customerId, contactId, billingAccountRes } = action.value;
      const { email, phone } = action.value.deliveryDetails;
      return {
        ...state,
        information: {
          ...state.information,
          clientContext: {
            customers: [{ customerId }],
            contact: {
              contactId,
              indentValue: state?.information?.anonymousIdentity,
              phone,
              email
            },
            billingArrangements: [
              { barId: billingAccountRes?.billingArangmentId }
            ]
          }
        }
      };
    }
    case ACTION_TYPES.USER.CALLER_BY_CONTACT_SUCCESS: {
      return {
        ...state,
        information: {
          ...state.information,
          ...formatNewUserData(action.payload)
        }
      };
    }
    case ACTION_TYPES.USER.SET_USER_LOADING: {
      return {
        ...state,
        error: false,
        loading: action.value,
        skeletonLoading: action.value
      };
    }
    case ACTION_TYPES.USER.SET_USER_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
        skeletonLoading: false
      };
    }
    case ACTION_TYPES.USER.SET_USER_PRODUCTS: {
      return {
        ...state,
        error: false,
        loading: false,
        products: action.value
      };
    }
    case API_ACTION_TYPES.IPHONE.VALIDATE_OTP_SUCCESS:
    case ACTION_TYPES.USER.SET_CIS_INFORMATION: {
      return {
        ...state,
        cis: {
          information: action.value || action.data,
          loading: false
        }
      };
    }
    case ACTION_TYPES.USER.SET_CIS_LOADING: {
      return {
        ...state,
        cis: {
          ...state.cis,
          loading: action.value
        }
      };
    }
    case ACTION_TYPES.USER.SET_CIS_ERROR: {
      return {
        ...state,
        cis: {
          ...state.cis,
          error: action.value
        }
      };
    }
    case ACTION_TYPES.USER.SET_SELECTED_SERVICE: {
      return {
        ...state,
        selectedService: action.value
      };
    }
    case ACTION_TYPES.USER.SET_USER_MAIN_LINES: {
      return { ...state, mainLines: action.value };
    }
    case ACTION_TYPES.USER.CIS_GET_MYINFO_ERROR_STATUS: {
      return {
        ...state,
        cis: {
          ...state.cis,
          information: {
            ...state.cis.information,
            cusMyInfo: {
              error: action.payload
            }
          }
        }
      };
    }
    case ACTION_TYPES.USER.CIS_GET_MYINFO_SUCCESS: {
      return {
        ...state,
        cis: {
          ...state.cis,
          information: {
            ...state.cis.information,
            cusMyInfo: action.payload
          }
        }
      };
    }
    case CIS_GET_MYINFO_LOADING: {
      return {
        ...state,
        cis: {
          ...state.cis,
          information: {
            ...state.cis?.information,
            cusMyInfo: {
              ...state.cis?.information?.cusMyInfo,
              loading: action.payload
            }
          }
        }
      };
    }
    case ACTION_TYPES.AUTH.SET_WORRY_FREE_DATA: {
      return {
        ...state,
        worryFreeData: action.data
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;

export const userTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    return inboundState;
  },
  // transform state being rehydrated
  (state /*key*/) => {
    const newState = {
      ...state,
      information: {
        ...state.information,
        cusMyInfo: {
          ...state.information.cusMyInfo,
          error: false,
          loading: false
        },
        loading: false,
        error: false
      }
    };

    return newState;
  },
  // define which reducers this transform gets called for.
  { whitelist: ['user'] }
);

export const resetUserForCheckout = () => dispatch => {
  dispatch({
    type: ACTION_TYPES.USER.RESET_USER_FOR_CHECKOUT
  });
};

export const resetUser = () => dispatch => {
  dispatch({
    type: ACTION_TYPES.USER.RESET_USER
  });
};

export const formatNewUserData = (userData = {}) => {
  const {
    contactBillingArrangementDetails: billingArrangements,
    contactDetails: contact,
    contactCustomerDetails: customers,
    contactFinancialAccountDetails: financialAccounts,
    ...rest
  } = userData;

  const clientContext = {
    billingArrangements,
    contact,
    customers,
    financialAccounts,
    ...rest
  };

  return { clientContext };
};
