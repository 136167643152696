import { APP_TYPE_ANY, AppState, KeyValue } from './common.types';

export interface CisInformationStore {
  sessionToken: string;
}

export interface UserStore {
  cis: {
    information: CisInformationStore;
  };
}

export interface CusMyInfo {
  customerId: string;
  passType: string;
  contactNumber: string;
}

export interface User extends AppState {
  cis: CisUser;
  information: UserInformation;
  products: Product[];
  selectedService: Product;
  worryFreeData?: WorryFreeData;
}

export interface Selection {
  plan: KeyValue;
}

export interface CisUser {
  information?: CisInformation;
  loading?: boolean;
}

export interface CisInformation {
  cusMyInfo?: CusMyInfo;
  $myInfo?: KeyValue;
  rates: CisPlanRates[];
}

export interface UserInformation {
  transactionId?: string;
  otpUser?: boolean;
  otpExpiredInSeconds?: number;
  userDetails?: UserDetails;
  clientContext?: ClientContext;
  sessionToken?: APP_TYPE_ANY;
  anonymousUser?: boolean;
  cis?: CisUser;
}

export interface CisPlanRates {
  planID: string;
  discountedPrice: number | string;
}

export interface UserDetails {
  loginId: string;
  custId: string;
  custIdType?: string;
  billPayer?: string;
  logiIdVerfied?: string;
}

export interface ClientContext {
  contact?: Contact;
  customers?: Customer[];
  billingArrangements?: BillingArrangement[];
  financialAccounts?: FinancialAccount[];
  subscriptions?: Subscription[];
  contactSubscriptionDetails?: ContactSubscriptionDetail[];
}

export interface Contact {
  redTag?: string;
  indentValue?: string;
  firstName?: string;
  gender?: string;
  phone?: string;
  indentType?: string;
  email?: string;
  lastName?: string;
  dateOfBirth?: number;
  registeredName?: string;
  contactId?: string;
}

export interface Customer {
  customerName: string;
  contactRole: string;
  customerSubType: string;
  customerId: string;
  customerType: string;
}

export interface BillingArrangement {
  barStatus: string;
  barCustomerId: string;
  contactRole: string;
  barId: string;
  barName: string;
}

export interface FinancialAccount {
  faName: string;
  contactRole: string;
  faId: string;
  isEasyMobile: boolean;
  faCustomerId: string;
  faStatus: string;
}

export enum LineOfBusiness {
  TV = 'TV',
  MOBILE = 'MOB',
  FIBRE_BROADBAND = 'FBB',
  RRP = 'RRP'
}

export interface Subscription {
  primaryResourceDmpX8: string;
  subType: string;
  contactRole: string;
  lineOfBusiness: LineOfBusiness;
  primaryResource: string;
  subscriberCustomerId: string;
  subscriberId: string;
  subscriberStatus: string;
}

export interface ContactSubscriptionDetail {
  primaryResourceDmpX8: string;
  subType: string;
  contactRole: string;
  lineOfBusiness: string;
  primaryResource: string;
  subscriberCustomerId: string;
  subscriberId: string;
  subscriberStatus: string;
}

export interface Product {
  productName: string;
  serviceId: string;
  offeringName: string;
  offeringId: string;
  personName: string;
  personId: string;
  id: string;
  status: string;
  customerId: string;
}

export interface Link {
  href: string;
  rel: string;
}

export interface Service {
  catalogItemID: string;
  initialActivationDate: number;
  businessType: string;
  catalogItemName: string;
  catalogItemDescription: string;
  id: string;
  status: string;
  serviceType: string;
}

export interface WorryFreeData {
  triggeredResend?: boolean;
  id: string;
  passType: string;
  mobileNumber: string;
  mobileNumberWithoutFormat: string;
}

export enum CustomerIdType {
  NRIC = 'National Registration ID Card (NRIC)',
  FIN = 'Foreign Identification Number (FIN)',
  DEPENDENT_PASS = 'Foreigner Dependent Pass',
  EMPLOYMENT_PASS = 'Foreigner Employment Pass',
  S_PASS = 'Foreigner S Pass',
  STUDENT_PASS = 'Foreigner Student Pass',
  PASSPORT = 'Passport Number',
  SOCIAL_VISIT_PASS = 'Foreigner Social Visit Pass',
  WORK_PERMIT = 'Foreigner Work Permit',
  DIPLOMAT = 'Diplomat'
}

export enum CustomerIdTypeValue {
  NRIC = '2',
  FIN = '6',
  DEPENDENT_PASS = '532',
  EMPLOYMENT_PASS = '534',
  S_PASS = '535',
  STUDENT_PASS = '533',
  PASSPORT = '536',
  SOCIAL_VISIT_PASS = '531',
  WORK_PERMIT = '530',
  DIPLOMAT = '537'
}

export enum ChangeContactField {
  FULL_NAME = 'fullName',
  NRIC = 'nric',
  DOB = 'dob',
  HEADER_TITLE = 'headerTitle',
  FOOTER_TITLE = 'footerTitle',
  CONTACT_NUMBER = 'contactNumber',
  EMAIL = 'email'
}
