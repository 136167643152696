import { FORM_FIELD_TYPES, FORM_VALIDATION_TYPES } from '@wec-core/form-engine';
import * as Yup from 'yup';
import { trans as t } from '../../../helpers/localisation';
import { ActionTypes } from '../../../types/orderSummary.types';
import { IFormFieldConfig } from '../../../types/registrationCheckout';
import { ChangeContactField } from '../../../types/user.types';

export const getFullName = (value: string): Partial<IFormFieldConfig> => {
  return {
    component: FORM_FIELD_TYPES.READ_ONLY_LABEL,
    name: 'fullName',
    label: t('PERSONAL_DETAILS_FULL_NAME') as string,
    valueLabel: value
  };
};

export const getNRIC = (value: string): Partial<IFormFieldConfig> => {
  return {
    component: FORM_FIELD_TYPES.READ_ONLY_LABEL,
    name: 'nric',
    label: t('PERSONAL_DETAILS_NRIC_OR_FIN') as string,
    valueLabel: value
  };
};

export const getDOB = (value: string): Partial<IFormFieldConfig> => {
  return {
    component: FORM_FIELD_TYPES.READ_ONLY_LABEL,
    name: 'dob',
    label: t('PERSONAL_DETAILS_DOB') as string,
    valueLabel: value
  };
};

export const getContactGroup = (
  fields: ChangeContactField[]
): Array<IFormFieldConfig> => {
  const mobile = {
    component: FORM_FIELD_TYPES.TEXT_INPUT,
    name: 'contactNumber',
    label: t('CO_NN_CONTACT_NUMBER') as string,
    displayFormat: 'mobile-sg',
    bgColor: 'haze',
    placeholder: t('CO_NN_CONTACT_NUMBER_PLACEHOLDER'),
    validations: [
      'string',
      {
        type: 'required',
        params: { message: t('CC_E_CONTACT') }
      },
      {
        type: FORM_VALIDATION_TYPES.MOBILE_NUM_VALIDATION,
        params: {
          message: t('CC_E_IV_CONTACT'),
          inValidLengthMsg: t('CC_E_IVL_CONTACT')
        }
      }
    ]
  };

  const email = {
    component: FORM_FIELD_TYPES.TEXT_INPUT,
    name: 'email',
    label: t('CO_NN_EMAIL') as string,
    bgColor: 'haze',
    placeholder: t('CO_NN_EMAIL_PLACEHOLDER'),
    validations: [
      'string',
      {
        type: 'required',
        params: { message: t('CC_E_EMAIL') }
      },
      {
        type: 'email',
        params: { message: t('CC_E_IV_EMAIL') }
      }
    ]
  };

  const fieldGroup = {
    [ChangeContactField.CONTACT_NUMBER]: mobile,
    [ChangeContactField.EMAIL]: email
  };

  return fields.reduce((result, field) => {
    if (fieldGroup[field]) {
      result.push(fieldGroup[field]);
    }
    return result;
  }, []);
};

export const footerTitle = {
  component: FORM_FIELD_TYPES.FORM_TITLE,
  name: 'formTitle',
  subTitle: t('PERSONAL_DETAILS_INFO')
};

export const getHeaderMessage = (title: string) => {
  return {
    component: FORM_FIELD_TYPES.FORM_TITLE,
    name: 'formTitle',
    subTitle: title
  };
};

export const confirmBtn = {
  component: FORM_FIELD_TYPES.FORM_SUBMIT_BUTTON,
  name: 'submitButton',
  label: t('CO_EN_ADD_ADDRESS_CTA_CONFIRM'),
  sizing: 3,
  btnProps: {
    fullWidth: false
  }
};

export const formattedPhoneNumber = phone => {
  if (!phone) return '';
  return phone.match(/\d{4}\s*\d{4}/)?.[0]?.replace(/\s/g, '');
};

export const getEmailAndPhone = details => {
  const {
    action: { type = '' },
    content = []
  } = details;

  if (
    ![
      ActionTypes.CHANGE_PERSONAL_INFO_EXISTING,
      ActionTypes.CHANGE_PERSONAL_INFO_RECON
    ].includes(type)
  ) {
    return null;
  }
  const [, , , phone = '', emailId] = content;
  return { phone: formattedPhoneNumber(phone), emailId };
};

export const compareAndGetContactDetails = (
  existingDetails,
  newDetails
): { emailId?: string; phone?: string } | null => {
  const { phone: existingPhone, emailId: existingEmail } = existingDetails;
  const { contactNumber: newPhone, email: newEmail } = newDetails;

  const formattedPhone = formattedPhoneNumber(newPhone);
  if (existingPhone === formattedPhone && existingEmail === newEmail) {
    return null;
  } else if (existingPhone !== formattedPhone && existingEmail === newEmail) {
    return { phone: formattedPhone };
  } else if (existingPhone === formattedPhone && existingEmail !== newEmail) {
    return { emailId: newEmail };
  } else {
    return { phone: formattedPhone, emailId: newEmail };
  }
};
