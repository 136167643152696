/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Ribbon } from '@dls/web';
import { navigate } from 'gatsby';
import { getUrlParams } from '@lux/helpers';
import SEO from '../../components/SEO';
import {
  IAccessories,
  IPlan,
  IVariant,
  HasNoStock,
  ProductDetails,
  scrollToSection
} from '@common-modules/product-catalogue';
import { Spacing } from '@dls/web';
import { PreSelection } from '../../types/product.types';
import { FLOW } from '../../types/plan.types';
import CisPointOfEntry from '../../components/CisPointOfEntry';
import { accessoryActions } from '@detox/actions';
import useTranslation from '../../hooks/useTranslation';
import {
  checkDefaultVariantChanged,
  getDeliveryOptions,
  getDeviceVariants,
  getPlanGroups,
  getPlans,
  getProductSpecsFromUrl,
  getPromotions,
  setUrlAfterChangeVariant,
  updateAccessories
} from '../../helpers/productDetails';
import useStockAvailability from '../../hooks/useStockAvailability';
import TransactionDialog from '../../components/TransactionDialog';
import OutOfStockDialog from '../../components/OutOfStockDialog';
import TermsAndConditions from '../../components/TermsAndConditions';
import CCEPrompt from '../../components/CCEPrompt';
import ShareModal from '../../components/ShareModal/ShareModal';
import {
  hideCampaigns,
  showCampaigns
} from '../../components/LivePerson/LivePerson';
import getAemComponentByName, {
  getAemComponentByKey
} from '../../helpers/getAemComponentByName';
import { COMMON } from '../../constants';
import {
  getAccessoryOnlineStock,
  mapAccessories
} from '../../helpers/accessory';
import { navigateToSpringDPlans } from '../../helpers/navigation';
import { cisGOMOPortInEntryUrl, simOnlyTncUrl } from '../../config/links';
import { phoneTagsConfig } from '../../config/phone-config';
import { AEM_CONFIG_KEYS } from '../../config/common';
import { AccessoryReducer } from '../../types/accessory.types';
import { getAdditionalMetaData, getShowAccessories } from './helpers';
import { ProductDetailsWrapper } from './styles';
import { useBrowserBack } from '../../hooks/useBrowserBack/useBrowserBack';
import { IphoneState } from '../../reducers/iPhone';
import { APP_TYPE_ANY, KeyValue } from '../../types/common.types';
import { ACTION_TYPES } from '../../constants/actions';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { CCE_IPHONE_FLOW } from '../../types/featureFlag.types';
import { getRibbon } from '../../helpers/catalogue-helpers';
import isSimOnlyPlan from '../../helpers/is-sim-only-plan';
import { setIppFilter, setRRPFlow } from '../../reducers/productCatalogs';
import { clearSpecialPromo } from '../../reducers/promotions';
import { useAuthToken } from '../../hooks/useAuthToken';
import { flattenNodes, isTagged, getAemConfig } from '../../helpers/common';
import { getUIAMEnabledWithToken } from '../../selectors';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const PhoneDetails = ({
  data,
  location,
  setSelectedProduct,
  setSelectedPlan,
  setUserSelection,
  selectedPlan,
  sessionToken,
  resetByKeys,
  auth,
  cisPlanRates
}) => {
  const {
    title,
    productId,
    colours,
    smallImage,
    imageGallery,
    videoUrl,
    ribbon,
    description,
    shippingLiner,
    simSlotsperSIMCardType,
    metaTitle,
    metaDescription,
    metaKeywords,
    freeGiftsByDevice,
    freeGiftsBySku,
    promotions,
    groupId,
    whatsInTheBox,
    specifications,
    slug,
    tags,
    recommendedAccessories = [],
    groupCampaignId,
    isRoi = false,
    brand
  } = data.phone;

  // iPhone launch pre-order
  const { iphone, apigeeTokenEnabled } = useSelector<
    { iphone: IphoneState },
    KeyValue
  >(state => {
    return {
      iphone: state?.iphone,
      apigeeTokenEnabled: getUIAMEnabledWithToken(state)
    };
  });
  const { sku: iPhoneSku, validateOtpData } = iphone;
  const defaultVariant = useRef<IVariant>(null);
  const isROIPhone = groupCampaignId || isRoi;
  const isCCEEnabled = isFeatureFlagEnabled(CCE_IPHONE_FLOW) || false;
  const [hasRoiToken, setRoiToken] = useState('');
  const [showCCEPrompt, setShowCCEPrompt] = useState(false);
  const skuVariants = useRef(data.phone.skuVariants);
  const cisPlanRatesRef = useRef(cisPlanRates);
  const additionalMetaData = getAdditionalMetaData({
    title,
    metaTitle,
    metaDescription,
    metaKeywords
  });
  const allVariableConfig = flattenNodes(data?.allVariableConfig || []);

  const showPlansInSwimlane = data?.allVariableConfig
    ? getAemConfig(
        allVariableConfig,
        AEM_CONFIG_KEYS.DETOX_ENABLE_PD_CARDS_SWIMLANE_VIEW
      ) === 'true'
    : false;

  useEffect(() => {
    showCampaigns(window.location.href);
    dispatch(setRRPFlow(false));
    dispatch(clearSpecialPromo());
    return () => hideCampaigns();
  }, []);

  useEffect(() => {
    if (iPhoneSku) {
      skuVariants.current = data.phone.skuVariants.filter(
        x => x.sku === iPhoneSku
      );
      cisPlanRatesRef.current = validateOtpData?.rates;

      if (apigeeTokenEnabled) {
        setFormattedDeviceVariants();
      }
    }
  }, [iPhoneSku, validateOtpData, apigeeTokenEnabled]);

  const { t } = useTranslation();
  const [transactionModalVisible, toggleTransactionModalVisible] = useState(
    false
  );
  const [toggleOOSModal, setToggleOOSModal] = useState({
    show: false,
    type: null
  });
  const [groupPlansTncModal, setGroupPlansTncModal] = useState(false);
  const [tncState, setTncState] = useState('');
  const [showShareModal, setShareModal] = useState<boolean>(false);
  const shareIconsList = process.env.GATSBY_SHARE_ICONS;
  const isCIS = Boolean(cisPlanRatesRef.current);
  const { allPlanGroup, allPlan, allAccessory, allAemPages, fulfilment } = data;
  const planGroups = flattenNodes(allPlanGroup);
  const allPlans = flattenNodes(allPlan);
  const accessories = flattenNodes(allAccessory);
  const dispatch = useDispatch();
  useBrowserBack();

  const selectedAccessories = useSelector<AccessoryReducer, IVariant[]>(
    state => state.accessory?.selectedAccessories
  );
  const siteConfigurations = getAemComponentByName(
    allAemPages,
    COMMON.AEM_COMPONENTS.SITE_CONFIGURATIONS
  );
  const [formattedAccessories, setFormattedAccessories] = useState<
    IAccessories[] | undefined
  >();

  const [freeGiftStocks] = useStockAvailability(
    freeGiftsByDevice,
    sessionToken,
    groupId
  );

  const [deliveryOptions, setDeliveryOptions] = useState(
    getDeliveryOptions(
      data?.fulfilment?.preOrderLinerForProductDetails,
      data?.fulfilment?.backOrderLinerForProductDetails,
      tags,
      fulfilment?.fulfilmentList,
      shippingLiner
    )
  );

  const isLifeStyleAccessory = isTagged(
    tags || [],
    phoneTagsConfig['home-lifestyle'].tagName
  );
  const switchesConfigurations = getAemComponentByKey(allAemPages, 'switches');
  const showAccessories = getShowAccessories(
    isLifeStyleAccessory,
    switchesConfigurations
  );

  const setFormattedDeviceVariants = async (needStockCheck = false) => {
    const formattedVariants = await getDeviceVariants({
      skuVariants: skuVariants.current,
      colors: colours,
      imageGallery,
      seoImageTag: smallImage.alt,
      plans: allPlans,
      groupId,
      tags,
      brand,
      needStockCheck
    });
    setFormattedVariants(formattedVariants);
  };

  const [formattedVariants, setFormattedVariants] = useState<{
    status: boolean;
    variants: IVariant[];
  }>(null);
  const isLoading = formattedVariants?.status === undefined;
  const isChangePlan = location?.state?.isChangePlan;

  const preSelection = useRef<PreSelection>(getProductSpecsFromUrl());

  useEffect(() => {
    const users = ['user'];

    resetByKeys(users);
  }, [resetByKeys]);

  useEffect(() => {
    const planObj = { plan: selectedPlan };

    setUserSelection(planObj);
  }, [selectedPlan]);

  useAuthToken({ auth }, [apigeeTokenEnabled]);

  useEffect(() => {
    setFormattedDeviceVariants();
  }, []);

  useEffect(() => {
    const _urlParams = getUrlParams();
    const roiToken = _urlParams.roiToken;

    if (isCCEEnabled && (roiToken || isROIPhone)) {
      setRoiToken(roiToken ? roiToken : COMMON.INVALID);
      setShowCCEPrompt(true);
      dispatch({
        type: ACTION_TYPES.IPHONE.SET_ROI_TOKEN,
        data: { roiToken, groupCampaignId }
      });
    } else if (apigeeTokenEnabled) {
      setFormattedDeviceVariants(true);
    }
  }, [apigeeTokenEnabled]);

  useEffect(() => {
    const setAccessories = async () => {
      let formattedAccessories = await mapAccessories(
        recommendedAccessories,
        accessories
      );
      if (selectedAccessories?.length) {
        formattedAccessories = updateAccessories(
          formattedAccessories,
          selectedAccessories
        );
      }
      setFormattedAccessories(formattedAccessories);
    };
    if (apigeeTokenEnabled) {
      setAccessories();
    }
  }, [apigeeTokenEnabled]);

  useEffect(() => {
    if (selectedAccessories?.length) {
      const updatedAccessories = updateAccessories(
        formattedAccessories,
        selectedAccessories
      );
      if (updatedAccessories) {
        setFormattedAccessories(updatedAccessories);
      }
    }
  }, [selectedAccessories]);

  useEffect(() => {
    if (isChangePlan && !isLoading) {
      setTimeout(() => {
        scrollToSection('plan-section');
      }, 0);
    }
  }, [isChangePlan, isLoading]);

  const getPreBackOrderLiner = variant => {
    const selectedSkuLeadDay =
      Array.isArray(fulfilment?.leadDays) &&
      fulfilment?.leadDays
        .filter(item => !!item.sku)
        .find(item => item.sku?.indexOf(variant.sku) > -1);

    if (selectedSkuLeadDay) {
      setDeliveryOptions({
        ...deliveryOptions,
        preOrderLiner: data?.fulfilment?.preOrderLinerForProductDetails.replace(
          /{deliveryperiod}/g,
          `${selectedSkuLeadDay.deliveryPeriod}`
        ),
        backOrderLiner: data?.fulfilment?.backOrderLinerForProductDetails.replace(
          /{deliveryperiod}/g,
          `${selectedSkuLeadDay.deliveryPeriod}`
        )
      });
    }
  };

  const onChangeVariant = (variant, queryParams = location.search) => {
    if (!defaultVariant.current) {
      if (variant.deviceCode && formattedVariants?.status) {
        defaultVariant.current = variant;
      }
    } else if (checkDefaultVariantChanged(defaultVariant.current, variant)) {
      defaultVariant.current = variant;
      setUrlAfterChangeVariant(variant, slug, queryParams);
    }
    const plan = allPlans.find(plan => plan.mecId === variant.planId);
    // Set plan for retaining the plan in URL
    if (selectedPlan?.mecId !== plan?.mecId && variant.stockCount) {
      setSelectedPlan({ ...plan, selectedMonthlyTerm: variant.monthlyTerm });
      if (isSimOnlyPlan(plan)) {
        dispatch(setIppFilter(true));
      } else {
        dispatch(setIppFilter(false));
      }
    }
    getPreBackOrderLiner(variant);
  };
  const ribbonForPlan = getRibbon(ribbon, selectedPlan);
  const productData = {
    freeGiftsBySku,
    freeGiftsByDevice,
    videoUrl,
    freeGiftStockAvail: freeGiftStocks,
    ribbon: ribbonForPlan?.title || ''
  };

  const handleGomoPortInClick = () => {
    if (!isCIS) navigateToSpringDPlans({ groupName: selectedPlan?.groupName });
    else {
      window.location.assign(cisGOMOPortInEntryUrl);
    }
  };

  const onNextClick = (
    variant: IVariant,
    plan: IPlan,
    accessories: IVariant[] = [],
    hasNoStock: HasNoStock
  ) => {
    if (hasNoStock) {
      setToggleOOSModal({ show: true, type: hasNoStock });
      return;
    }

    setSelectedPlan({ ...plan, selectedMonthlyTerm: variant.monthlyTerm });

    setSelectedProduct({
      ...variant,
      imageGallery,
      title,
      tags,
      shippingLiner,
      simSlotsperSIMCardType
    });
    dispatch(accessoryActions.storeSelectedAccessories(accessories));
    toggleTransactionModalVisible(true);
  };

  const onOOSLinkClick = () => {
    if (window?.history.length > 2) {
      navigate(-1);
    } else {
      navigate(`/`);
    }
  };

  const renderAccessoryRibbon = (accessory: APP_TYPE_ANY) => {
    switch (isCIS) {
      case true:
        return accessory?.ribbonCIS ? (
          <Ribbon.Variant1>{accessory.ribbonCIS}</Ribbon.Variant1>
        ) : null;
      case false:
        return accessory?.ribbon ? (
          <Ribbon.Variant1>{accessory.ribbon}</Ribbon.Variant1>
        ) : null;
      default:
        return null;
    }
  };

  const toggleShareModal = () => {
    setShareModal(!showShareModal);
  };

  return (
    <div className="fs-unmask">
      <SEO
        title={title}
        pathname={`/phones/${slug}`}
        description={description}
        microdata={{}}
        additionalMeta={additionalMetaData}
      />
      {!isCIS && (
        <Spacing top={-3} topMd={-6}>
          <CisPointOfEntry />
        </Spacing>
      )}
      {hasRoiToken ? (
        <>
          <CCEPrompt
            cceModalToggle={showCCEPrompt}
            setCCEModalToggle={(isNextBtnClick = false, navigatePath = '/') => {
              setShowCCEPrompt(false);
              setRoiToken(null);
              if (!isNextBtnClick) {
                navigate(navigatePath, { replace: true });
              }
            }}
            data={data.phone}
            roiToken={iphone.roiToken}
            onChangeVariant={onChangeVariant}
            allPlans={allPlans}
            isROIPhone={isROIPhone}
          />
        </>
      ) : (
        <ProductDetailsWrapper>
          <ProductDetails
            data={{
              productDetails: {
                ...productData,
                deviceId: productId,
                variants: formattedVariants?.variants || [],
                promotions: getPromotions({ selectedPlan, promotions, isCIS }),
                accessories: formattedAccessories,
                plan: {
                  title: t('CHOOSE_YOUR_SERVICE'),
                  planGroups: getPlanGroups(
                    planGroups,
                    allPlans,
                    isCIS ? FLOW.CIS : FLOW.RES
                  ),
                  allPlans: getPlans(allPlans, cisPlanRatesRef.current)
                },
                preSelectionOptions: {
                  storage: preSelection.current?.storage,
                  color: preSelection.current?.color,
                  plan: preSelection.current?.plan || selectedPlan?.planName,
                  monthlyTerm: preSelection.current?.monthlyTerm,
                  accessories: (selectedAccessories ?? []).map(
                    accessoryVariant => accessoryVariant.id
                  )
                },
                deliveryOptions
              },
              productModel: {
                brandCode: '',
                brandDescription: 'selectedPhone.brand',
                modelCode: productId,
                modelDescription: title,
                whatsInTheBox: whatsInTheBox,
                specifications: specifications
              }
            }}
            configs={{
              showAccessories,
              isLoading: isLoading,
              moduleLoaders: {
                accessory: formattedAccessories === undefined
              },
              dataMapping: {
                deviceId: 'modelCode',
                brandId: 'brandCode',
                deviceTitle: 'modelDescription',
                imageFile: 'image',
                productCost: 'phoneCost'
              },
              baseUrl: location.origin,
              isQuantityRequired: false,
              simOnlyTncUrl: simOnlyTncUrl,
              showShareBtn:
                !isLoading &&
                shareIconsList &&
                shareIconsList !== COMMON.SHARE_ICON.NONE,
              showPlansInSwimlane
            }}
            localisation={{
              EQUIPMENT_MOBILE_DEVICE_TITLE: 'Phones'
            }}
            callbacks={{
              onChangeVariant,
              onNextClick,
              onOOSLinkClick,
              checkStock: getAccessoryOnlineStock,
              renderAccessoryRibbon,
              onShareClick: toggleShareModal
            }}
          />
          <TransactionDialog
            open={transactionModalVisible}
            isGomoToSingtelEnabled={
              siteConfigurations?.switches?.DETOX_GOMO_TO_SINGTEL
            }
            onClickGomoPortIn={handleGomoPortInClick}
            onClose={() => toggleTransactionModalVisible(false)}
            data-testid="transaction-selection"
          />
          <OutOfStockDialog
            type={toggleOOSModal.type}
            isModalOpen={toggleOOSModal.show}
            onProceedClick={onOOSLinkClick}
            onClose={() =>
              setToggleOOSModal(prevState => ({ ...prevState, show: false }))
            }
            data-testid="out-of-stock-dialog"
          />
          <TermsAndConditions
            isModalOpen={groupPlansTncModal}
            setIsModalOpen={setGroupPlansTncModal}
            tncType={tncState}
            setTncType={setTncState}
          />
          {shareIconsList && shareIconsList !== COMMON.SHARE_ICON.NONE && (
            <ShareModal showModal={showShareModal} onClose={toggleShareModal} />
          )}
        </ProductDetailsWrapper>
      )}
    </div>
  );
};
