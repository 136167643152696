import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import { noop } from '@lux/helpers';
import {
  authActions,
  planActions,
  productActions,
  rootActions
} from '@detox/actions';
import '../../fragments/phone-detail';
import '../../fragments/plan-detail';
import '../../fragments/aem-components';
import '../../fragments/accessoryVariant';
import { PhoneDetails } from './phoneDetails';

export const PhoneTemplate = props => {
  return <PhoneDetails {...props} />;
};

PhoneTemplate.defaultProps = {
  exclusiveValidation: {
    show: true
  },
  getAuthToken: noop,
  setSelectedProduct: noop,
  setSelectedPlan: noop,
  setUserSelection: noop,
  resetByKeys: noop
};

PhoneTemplate.propTypes = {
  data: PropTypes.object,
  exclusiveValidation: PropTypes.shape({
    show: PropTypes.bool
  }),
  getAuthToken: PropTypes.func,
  sessionToken: PropTypes.string,
  setSelectedProduct: PropTypes.func,
  setSelectedPlan: PropTypes.func,
  selectedProduct: PropTypes.object,
  selectedPlan: PropTypes.object,
  productOrder: PropTypes.object,
  productOrderIsLoading: PropTypes.bool,
  resetByKeys: PropTypes.func,
  cisPlanRates: PropTypes.arrayOf(
    PropTypes.shape({
      planID: PropTypes.string,
      discountedPrice: PropTypes.string
    })
  )
};

/* istanbul ignore next */
const mapStateToProps = state => {
  const { auth, order, plan, product, setting, user } = state;

  return {
    selectedProduct: product.selectedProduct,
    selectedPlan: plan.selectedPlan,
    auth: auth,
    sessionToken: auth.sessionToken,
    productOrder: order.productOrder,
    productOrderIsLoading: order.productOrderIsLoading,
    cisPlanRates: user.cis.information && user.cis.information.rates,
    exclusiveValidation: setting.exclusiveValidation
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  const { setSelectedProduct } = productActions;
  const { setSelectedPlan } = planActions;
  const { getAuthToken, resetWorryFree } = authActions;
  const { resetByKeys } = rootActions;

  return {
    getAuthToken: options => dispatch(getAuthToken(options)),
    hideExclusiveValidation: () =>
      dispatch({
        type: 'EXCLUSIVE_VALIDATION_HIDE'
      }),
    setUserSelection: selection =>
      dispatch({
        type: 'SET_USER_SELECTION',
        payload: selection
      }),
    setSelectedProduct: product => dispatch(setSelectedProduct(product)),
    setSelectedPlan: plan => dispatch(setSelectedPlan(plan)),
    resetByKeys: keys => dispatch(resetByKeys(keys)),
    resetWorryFree: () => dispatch(resetWorryFree())
  };
};

const ConnectedPhoneTemplate = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneTemplate);

export default ConnectedPhoneTemplate;

export const query = graphql`
  query PhoneByIndex($id: String) {
    phone(id: { eq: $id }) {
      ...PhoneDetailFragment
    }
    allPlan(filter: { enabledOnPhoneDetail: { eq: true } }) {
      edges {
        node {
          ...PlanDetailFragment
        }
      }
    }
    allAccessory {
      edges {
        node {
          title
          description
          tags
          ranking
          ribbon
          ribbonCIS
          ribbonStaff
          variants {
            ...AccessoryVariantFragment
          }
        }
      }
    }
    allPlanGroup(filter: { enabledOnPhoneDetail: { eq: true } }) {
      edges {
        node {
          defaultPlan
          groupName
          groupDisplayName
          enabledOnPhoneDetail
          shortDescription
          segment
          footerLiner
        }
      }
    }
    fulfilment {
      id
      backOrderLinerForFulfilment
      backOrderLinerForProductDetails
      preOrderLinerForFulfilment
      preOrderLinerForProductDetails
      leadDays {
        sku
        firstDeliveryDate
        lastDeliveryDate
        deliveryPeriod
      }
      fulfilmentList {
        fulfilmentId
        mobileFulfilment {
          enableForPickupType
          desc
          enableForBackOrder
          enableUploadDocument
          enabledForAccessories
          enabledForFreeGifts
          enabledForHandset
          enabledForSIM
          enabledForTradeIn
          liner
          maxAllowedItems
          title
          price
          ribbon
        }
        tags
      }
    }
    allAemPages(filter: { pageUrl: { eq: "/eshop/admin.xjson" } }) {
      edges {
        node {
          title
          components {
            ...AemComponentsFragment
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    allVariableConfig {
      edges {
        node {
          name
          value
        }
      }
    }
  }
`;
