import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { addonsActions, orderActions } from '@detox/actions';

import { navigation } from '../../middlewares/navigation-constants';
import { APP_TYPE_ANY } from '../../types/common.types';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import {
  FEATURE_FLAG_OFFER_TYPE_SIMONLY,
  FEATURE_FLAG_SPECIAL_PROMO
} from '../../types/featureFlag.types';
import isSimOnlyPlan from '../../helpers/is-sim-only-plan';
import { ADDON } from '../../constants';
import { CONTAINMENT_IDS } from '../../constants/addon';
import { FLOW } from '../../types/plan.types';
import { getSkipAddonsFromConfig } from '../../helpers/common';
import { ACTION_TYPES } from '../../constants/actions';

const { getAddonsFromOrder } = addonsActions;
const { updateOrderToRunRules } = orderActions;

interface RootState {
  [key: string]: Record<string, Record<string, unknown> | APP_TYPE_ANY>;
}

interface AddonsHelperReturnType {
  handleGoToNextPage: () => Promise<void>;
  loadAddons: () => Promise<void>;
}

interface AddonsHelperInputsType {
  [key: string]: APP_TYPE_ANY;
}
const isGomoFlow = isFeatureFlagEnabled('GOMO_FLOW');

export const useAddonsHelper = ({
  allAddon,
  addonSimOnlyPlan,
  allApplePhone,
  appleCareAddons,
  loadOnInitiate = false,
  skipAddonsConfig = ''
}: AddonsHelperInputsType): AddonsHelperReturnType => {
  const isSpecialPromoEnabled = isFeatureFlagEnabled(
    FEATURE_FLAG_SPECIAL_PROMO
  );
  const {
    selectedPlan,
    order,
    promotions,
    addons,
    user,
    product
  } = useSelector((state: RootState) => {
    return {
      selectedPlan: state.plan?.selectedPlan,
      order: state.order,
      promotions: state.promotions,
      addons: state.addons,
      user: state.user,
      product: state.product
    };
  });
  const dispatch = useDispatch();

  const { productOrder } = order;
  const {
    productOrderId,
    productOrderItemId,
    service: productOrderService,
    sim
  } = productOrder || {};
  const { selectedService, cis: cisInfo, information } = user;
  const { specialPromo } = promotions;
  const { selectedProduct } = product;
  const userContact = information?.clientContext?.contact;
  const infoCustomerId = information?.clientContext?.customers?.[0]?.customerId;
  const flowType = cisInfo?.information ? FLOW.CIS : FLOW.RES;

  const handleGoToNextPage = async () => {
    const { productId } = productOrder;
    const { promoOrderDetails } = promotions;
    const isOfferTypeSimOnlyEnabled = isFeatureFlagEnabled(
      FEATURE_FLAG_OFFER_TYPE_SIMONLY
    );

    try {
      await (dispatch(
        updateOrderToRunRules({
          productId,
          productOrderItemId,
          selectedAddonIds: addons.selectedAddons,
          promoOrderDetails: {
            ...promoOrderDetails,
            sourcePlan: selectedService?.offeringId
          },
          isSimOnlyPlan:
            isSimOnlyPlan(selectedPlan) && isOfferTypeSimOnlyEnabled,
          ...(specialPromo?.promoCode &&
            isSpecialPromoEnabled && {
              specialPromoCode: specialPromo?.promoCode
            })
        } as APP_TYPE_ANY)
      ) as APP_TYPE_ANY);
    } catch (e) {
      return;
    }

    return navigate(`/${navigation.SHOPPING_CART_PAGE}`);
  };

  const loadAddons = useCallback(async () => {
    if (productOrderItemId && information) {
      const productOrderHasDevice = Boolean(selectedProduct);
      const customerId = productOrderService?.customerId;
      let addonsForSelectedDevice;

      if (productOrderHasDevice) {
        addonsForSelectedDevice = allAddon.filter(
          ({ phoneList, flowType: addOnFlowType }) => {
            /**
             * If the addon is for a device, it must contain the selected device
             * Allow addons that are not supposed to have a device list.
             */
            const isAddonForFlow =
              !addOnFlowType?.length || addOnFlowType?.includes(flowType);
            if (phoneList?.length === 0) return isAddonForFlow;

            const selectedDeviceGroupId = selectedProduct.groupId;
            return phoneList?.includes(selectedDeviceGroupId) && isAddonForFlow;
          }
        );
      } else {
        const { data, sms, talktime } = addonSimOnlyPlan;

        const dataIds = data.map(({ addonId, quantity }) => ({
          productTitle: quantity,
          productSpecContainmentID: addonId,
          simOnlyAddon: true
        }));
        const talkTimeIds = talktime.map(({ addonId, quantity }) => ({
          productTitle: quantity,
          productSpecContainmentID: addonId,
          simOnlyAddon: true
        }));
        const smsIds = sms.map(({ addonId, quantity }) => ({
          productTitle: quantity,
          productSpecContainmentID: addonId,
          simOnlyAddon: true
        }));

        /**
         * Filter all AEM addons that match the selected device only.
         */
        addonsForSelectedDevice = allAddon.concat([
          ...dataIds,
          ...smsIds,
          ...talkTimeIds
        ]);

        // If device is not selected, skip addons with requiredDevice=true.
        addonsForSelectedDevice = addonsForSelectedDevice.filter(
          addon => !addon.requiredDevice
        );
      }
      const isOrderActionCalled =
        addons.orderActionAttributes &&
        productOrderItemId === addons.orderActionAttributes?.productOrderItemId;
      const userInfoData = userContact
        ? {
            ...userContact,
            contactId: infoCustomerId,
            selectedAddonsPayload: false,
            tradeInProductId: CONTAINMENT_IDS.TRADE_IN
          }
        : {
            selectedAddonsPayload: false,
            tradeInProductId: CONTAINMENT_IDS.TRADE_IN
          };
      await (dispatch(
        getAddonsFromOrder({
          devices: allApplePhone,
          addons: appleCareAddons,
          productOrderItemId,
          aemAddonsList: addonsForSelectedDevice,
          customerId,
          productOrderId,
          orderActionsPayload: !isOrderActionCalled ? userInfoData : null,
          bundleAddonsIds: ADDON.BUNDLE_ADDON_IDS,
          containmentIds: ADDON.CONTAINMENT_IDS,
          pretickAddonBoIds: order?.productOrder?.preTickAddonsBoIds,
          manuallyAddedAddons: addons?.manuallyAddedAddons,
          ...(specialPromo?.promoCode &&
            isSpecialPromoEnabled && {
              specialPromoCode: specialPromo?.promoCode
            })
        })
      ) as APP_TYPE_ANY);
    }
  }, [
    addonSimOnlyPlan,
    allAddon,
    allApplePhone,
    appleCareAddons,
    getAddonsFromOrder,
    productOrderItemId,
    productOrderService,
    productOrderId,
    information,
    addons?.manuallyAddedAddons
  ]);

  useEffect(() => {
    const handleAddons = async () => {
      // reset order create flag
      dispatch({
        type: ACTION_TYPES.ORDER.RESET_ORDER_CREATE_FLAG
      });

      await loadAddons();
      await handleGoToNextPage();
    };

    const skipAddons = getSkipAddonsFromConfig(
      skipAddonsConfig,
      selectedPlan?.planRelId,
      specialPromo
    );

    if (loadOnInitiate && skipAddons && order.productOrderCreated) {
      const blockSingtelPortIn =
        !isGomoFlow &&
        (order.productOrder?.telcoType === 'SINGTELPOSTPAID' ||
          order.productOrder?.telcoType === 'SINGTELPREPAID');

      if (!blockSingtelPortIn) {
        handleAddons();
      }
    }
  }, [order?.productOrderCreated]);

  return { handleGoToNextPage, loadAddons };
};
