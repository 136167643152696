import {
  setFreebieStatus,
  getCartDisplayedData,
  hasSilverDiscountInfoError
} from '../helpers/cart';
import { REQUEST_STATUS } from '../constants';
import { ACTION_TYPES } from '../constants/actions';

export const initialState = {
  status: REQUEST_STATUS.IDLE,
  acknowledgeNotification: false,
  bibDisplayData: null,
  skipEmptyCart: false
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.CART.SHOPPING_CART_LOADING: {
      return {
        ...state,
        skeletonLoading: true,
        checkoutLoading: false,
        cartOrder: null,
        order: null,
        error: false,
        status: REQUEST_STATUS.PENDING,
        skipEmptyCart: false
      };
    }
    case ACTION_TYPES.CART.SHOPPING_CART_ERROR: {
      return {
        ...state,
        order: null,
        cartOrder: null,
        skeletonLoading: false,
        error: true,
        status: REQUEST_STATUS.FAILED
      };
    }

    case ACTION_TYPES.CART.SHOPPING_CART_SUCCESS: {
      const cartOrder = getCartDisplayedData(action.value);
      return {
        ...state,
        order: action.value,
        cartOrder,
        skeletonLoading: false,
        error: false,
        status: REQUEST_STATUS.SUCCEEDED,
        checkoutLoading: false,
        checkoutErrorData: null,
        checkoutInfoData: null,
        checkoutError: false,
        checkoutOrderError: false
      };
    }

    case ACTION_TYPES.CART.EMPTY_CART_LOADING: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case ACTION_TYPES.CART.EMPTY_CART_SUCCESS: {
      return {
        ...state,
        order: null,
        cartOrder: null,
        loading: false,
        error: false,
        skipEmptyCart: action.value?.skipEmptyCart
      };
    }

    case ACTION_TYPES.CART.EMPTY_CART_ERROR: {
      return {
        ...state,
        loading: false,
        error: true
      };
    }

    case ACTION_TYPES.CART.CHECKOUT_LOADING: {
      return {
        ...state,
        checkoutLoading: true,
        checkoutError: false,
        checkoutErrorData: null,
        checkoutInfoData: null,
        checkoutOrderError: false,
        checkoutErrorScenarioCheckCompleted: false
      };
    }

    case ACTION_TYPES.CART.CHECKOUT_SUCCESS: {
      return {
        ...state,
        checkoutLoading: false,
        checkoutError: false,
        checkoutErrorData: null,
        checkoutInfoData: null,
        checkoutOrderError: false
      };
    }

    case ACTION_TYPES.CART.CHECKOUT_ERROR: {
      return {
        ...state,
        checkoutLoading: false,
        checkoutError: true,
        checkoutErrorData: action.value,
        checkoutInfoData: null,
        checkoutOrderError: false
      };
    }

    case ACTION_TYPES.CART.CHECKOUT_ERROR_SCENARIO_CHECK: {
      if (
        action.value?.severity === 'INFORMATION' &&
        action.value?.validateError
      ) {
        const hasSilverDiscountInfo = hasSilverDiscountInfoError(
          action.value.validateError
        );

        return {
          ...state,
          checkoutErrorScenarioCheckCompleted: true,
          checkoutOrderError: false,
          checkoutInfoData: hasSilverDiscountInfo
        };
      }

      return {
        ...state,
        checkoutErrorScenarioCheckCompleted: true,
        checkoutErrorData: action.value,
        checkoutOrderError: !!action.value,
        checkoutInfoData: null
      };
    }

    case ACTION_TYPES.CART.CHECKOUT_RESET_ERROR_SCENARIO_CHECK: {
      return {
        ...state,
        checkoutErrorScenarioCheckCompleted: false
      };
    }

    case ACTION_TYPES.CART.CHECKOUT_ORDER_ERROR: {
      return {
        ...state,
        checkoutLoading: false,
        checkoutError: false,
        checkoutErrorData: action.value,
        checkoutOrderError: true
      };
    }

    case ACTION_TYPES.CART.CLEAR_CHECKOUT_ERROR: {
      return {
        ...state,
        checkoutError: false,
        checkoutOrderError: false,
        checkoutErrorData: null,
        checkoutInfoData: null
      };
    }

    case ACTION_TYPES.CART.ACKNOWLEDGE_NOTIFICATION: {
      return {
        ...state,
        acknowledgeNotification: true
      };
    }

    case ACTION_TYPES.CART.RRP_SHOPPING_CART_SUCCESS: {
      return {
        ...state,
        rrpOrder: action.value
      };
    }
    case ACTION_TYPES.PAYMENT.CLEAR_BUY_FLOW_DATA: {
      return initialState;
    }

    case ACTION_TYPES.FULFILMENT.SUBMIT_FULFILLMENT_SUCCESS: {
      const removeProducts = action?.payload?.outOfStockFreebies || [];
      if (removeProducts?.length) {
        const freebies = setFreebieStatus(
          removeProducts,
          state.cartOrder?.freebies
        );
        return {
          ...state,
          cartOrder: {
            ...state.cartOrder,
            freebies
          }
        };
      }
      return state;
    }

    case ACTION_TYPES.CART.BIB_SET_DISPLAY_DATA: {
      return {
        ...state,
        bibDisplayData: {
          ...state.bibDisplayData,
          ...action.data
        }
      };
    }

    case ACTION_TYPES.ADDONS.ADDONS_FETCH_LOADING: {
      return {
        ...state,
        bibDisplayData: null,
        order: {
          ...state.order,
          bib: null
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default cartReducer;

export const setBibDisplayData = data => dispatch => {
  dispatch({
    type: ACTION_TYPES.CART.BIB_SET_DISPLAY_DATA,
    data
  });
};
