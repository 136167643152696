import { ACTION_TYPES } from '../constants/actions';

export const initialState = {
  exclusiveValidation: {
    show: true
  },
  maintenanceMessage: {
    show: false
  },
  appConfig: {}
};

const settingReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case 'DETOX_MAINTENANCE_SHOW':
      return {
        ...state,
        maintenanceMessage: {
          show: true
        }
      };
    case 'DETOX_MAINTENANCE_HIDE':
      return {
        ...state,
        maintenanceMessage: {
          show: false
        }
      };
    case 'EXCLUSIVE_VALIDATION_SHOW':
      return {
        ...state,
        exclusiveValidation: {
          show: true
        }
      };
    case 'EXCLUSIVE_VALIDATION_HIDE':
      return {
        ...state,
        exclusiveValidation: {
          show: false
        }
      };

    case ACTION_TYPES.SETTINGS.SET_APP_CONFIG: {
      return {
        ...state,
        appConfig: { ...state.appConfig, ...action.payload }
      };
    }
    case ACTION_TYPES.USER.SET_USER_SELECTION:
    case ACTION_TYPES.PAYMENT.CLEAR_BUY_FLOW_DATA:
    case ACTION_TYPES.ORDER.RESET_ORDER:
    case ACTION_TYPES.SETTINGS.RESET_APP_CONFIG: {
      return {
        ...state,
        appConfig: {}
      };
    }

    default:
      return state;
  }
};

export default settingReducer;
