import React, { ReactElement } from 'react';
import { Grid, Row, Column, Spacing } from '@dls/web';

import { APP_TYPE_ANY, KeyValue } from '../../../types/common.types';
import AddonsListItem from './AddonsListItem';
import AddonsGroupListItem from './AddonsGroupListItem';
import AddonsSimOnlyItems from './AddonsSimOnlyItems';

interface TProps extends KeyValue {
  addonGroupInfoLookup: KeyValue;
  addonGroups: KeyValue[];
  onAdd: (addon: APP_TYPE_ANY) => void;
  onRemove: (addon: APP_TYPE_ANY) => Promise<void>;
  onLearnMore: ({ addon }) => void | Promise<void>;
  onGroupAdd: (addonData) => void | Promise<void>;
  onSimOnlyGroupAdd?: (addonData) => void | Promise<void>;
  onSimOnlyRemove?: (addon: APP_TYPE_ANY) => Promise<void>;
}

const AddonsList = (props: TProps): ReactElement => {
  const {
    addonGroups = [],
    addonGroupInfoLookup,
    onAdd,
    onGroupAdd,
    onSimOnlyGroupAdd,
    onRemove,
    onLearnMore,
    onSimOnlyRemove
  } = props;

  return (
    <Grid>
      <Spacing leftRight={-2} type="margin">
        <Row>
          {addonGroups.map(group => {
            const { addons = [], groupName, isSimOnly } = group;

            if (isSimOnly) {
              return (
                <Column xs={12} sm={12} md={4} key={groupName}>
                  <AddonsSimOnlyItems
                    groupName={groupName}
                    group={group}
                    onSimOnlyAdd={onSimOnlyGroupAdd}
                    onSimOnlyRemove={onSimOnlyRemove}
                  />
                </Column>
              );
            }

            const addonGroupInfo = addonGroupInfoLookup[group.groupName];

            const addonsInGroup = addons.map(addon => ({
              ...(addon || []),
              iconImage: addonGroupInfo?.iconImage
            }));

            if (addonsInGroup.length <= 0) return null;

            if (addonsInGroup.length === 1 || !addonGroupInfo) {
              // These are standalone addons
              if (addonsInGroup[0].isNotRemovable) {
                return null;
              }
              return (
                <Column xs={12} sm={12} md={4} key={groupName}>
                  <AddonsListItem
                    addon={addonsInGroup[0]}
                    key={groupName}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    onLearnMore={onLearnMore}
                  />
                </Column>
              );
            } else {
              if (group.isNotRemovable) {
                return null;
              }
              return (
                <Column xs={12} sm={12} md={4} key={groupName}>
                  <AddonsGroupListItem
                    group={group}
                    groupInfo={addonGroupInfo}
                    key={groupName}
                    onGroupAdd={onGroupAdd}
                    onRemove={onRemove}
                    onLearnMore={onLearnMore}
                  />
                </Column>
              );
            }
          })}
        </Row>
      </Spacing>
    </Grid>
  );
};

export default AddonsList;
