import { navigate } from 'gatsby';
import isFeatureFlagEnabled from '../helpers/feature-flags';
import { navigation } from './navigation-constants';

export const getDetoxStore = store => {
  const state = store.getState();
  const {
    auth,
    order,
    plan,
    product,
    user,
    fulfillment,
    cart,
    setting
  } = state;
  const { selectedPlan } = plan;
  const { selectedProduct } = product;
  const { productOrder } = order;
  const { information: userInformation, selectedService } = user;
  const { information: cis } = user.cis || {};
  const { appConfig } = setting;

  return {
    auth,
    selectedPlan,
    selectedProduct,
    selectedService,
    productOrder,
    userInformation,
    fulfillment,
    cart,
    cis,
    appConfig
  };
};

export const navigateToCheckOut = async pageToNavigate => {
  navigate('/' + pageToNavigate);
};

export const handleGoToAddons = (store, action) => {
  const { productOrder, appConfig } = getDetoxStore(store);

  const { telcoType, type } = action.value;

  const isGomoFlow = isFeatureFlagEnabled('GOMO_FLOW');

  const isTelcoSingtel =
    telcoType === 'SINGTELPOSTPAID' || telcoType === 'SINGTELPREPAID';

  // Gomo porting in
  if (type === 'PORT-IN') {
    // We need more information about the user before proceeding on to addons page
    if (isGomoFlow && isTelcoSingtel && productOrder.changeOwnershipRequired) {
      return navigate('/number-verification');
    }
    // We have enough about the user, proceed to addons page
    if (
      isGomoFlow &&
      isTelcoSingtel &&
      !productOrder.changeOwnershipRequired &&
      !appConfig.skipAddons
    ) {
      return navigate(`/${navigation.ADDONS}`, { replace: true });
    }
    // block the navigation if gomo flow is turned off and is a singtel number
    if (!isGomoFlow && isTelcoSingtel) {
      return false;
    }
  }

  if (appConfig.skipAddons) {
    return;
  }

  return navigate(`/${navigation.ADDONS}`);
};

export const handleCheckout = async store => {
  const { productOrder, userInformation, cart } = getDetoxStore(store);
  if (Boolean(cart?.cartOrder?.accessories?.[0]?.ippDetails)) return; // TODO: prevent redirection from middleware

  const isUserHavingInfo = userInformation && userInformation?.clientContext;
  if (productOrder && isUserHavingInfo) {
    return navigate(`/${navigation.CHECKOUT}`);
  } else {
    return navigate(`/${navigation.LOGIN_GUEST_CHECKOUT}`);
  }
};

const handleOrderError = (store, action) => {
  if (action.value?.errorMsg === 'PORT_IN_GENERIC_ERROR') {
    return navigate('/number-selection#port-in');
  }
};

const eventsMap = {
  SET_PRODUCT_ORDER_SUCCESS: handleGoToAddons,
  CHECKOUT_SUCCESS: handleCheckout,
  SET_PRODUCT_ORDER_ERROR: handleOrderError
};

const navigationMiddleware = store => next => action => {
  next(action);
  eventsMap[action.type] && eventsMap[action.type](store, action);
};

export default navigationMiddleware;
