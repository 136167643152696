import { ACTION_TYPES } from '../constants/actions';
import { createSelector } from 'reselect';
import ORDER from '../constants/order';
import { ACTION_TYPES as PACKAGE_ACTIONS_TYPES } from '@detox/actions';
import {
  CHOOSE_SIM_TYPE,
  selectedSimTypeMap
} from '../constants/choose-sim-type';

export const productOrder = state => state.order?.productOrder;

export const isNewSignUp = createSelector(
  [productOrder],
  order => order?.type === ORDER.TYPE.NEW
);

export const initialState = {
  error: false,
  loading: false,
  productOrder: undefined,
  productOrderCreated: false,
  addedToContact: false,
  hasProcessingOrder: false
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.PAYMENT.CLEAR_BUY_FLOW_DATA: {
      return {
        selectedSimType: state.selectedSimType,
        ...initialState
      };
    }
    case ACTION_TYPES.ORDER.RESET_ORDER: {
      return initialState;
    }
    case ACTION_TYPES.ORDER.SET_PORT_IN_NUMBER: {
      return {
        ...state,
        portInNumber: action.payload.portInNumber
      };
    }
    case ACTION_TYPES.ORDER.RESET_CHOOSE_NUMBER_ERROR: {
      return {
        ...state,
        productOrder: {
          ...state.productOrder,
          telcoType: undefined
        }
      };
    }

    case ACTION_TYPES.ORDER.UPDATE_PRODUCT_ORDER: {
      return {
        ...state,
        productOrder: {
          ...state.productOrder,
          ...action.value
        }
      };
    }
    case ACTION_TYPES.ORDER.SET_PRODUCT_ORDER_SUCCESS: {
      return {
        ...state,
        productOrder: action.value,
        productOrderCreated: true,
        loading: false,
        error: false
      };
    }

    case ACTION_TYPES.ORDER.SET_PRODUCT_ORDER_LOADING: {
      return {
        ...state,
        productOrder: undefined,
        productOrderCreated: false,
        loading: action.value,
        error: false,
        reserveResource: {
          ...state.reserveResource,
          success: false,
          loading: true,
          error: false
        }
      };
    }

    // This reducer exists because sometimes we don't want
    // productOrder to be reset like the 'SET_PRODUCT_ORDER_LOADING'
    // reducer does. One example is assigning a number to an order,
    // where we still want the order to be intact.
    case ACTION_TYPES.ORDER.SET_ORDER_LOADING: {
      return {
        ...state,
        loading: action.value,
        error: false
      };
    }
    case ACTION_TYPES.ORDER.SET_PRODUCT_ORDER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.value
      };
    }

    case ACTION_TYPES.ORDER.SET_RESERVE_RESOURCE_SUCCESS: {
      return {
        ...state,
        reserveResource: {
          ...state.reserveResource,
          success: true,
          loading: false,
          error: false
        }
      };
    }

    case ACTION_TYPES.ORDER.SET_RESERVE_RESOURCE_ERROR: {
      return {
        ...state,
        reserveResource: {
          ...state.reserveResource,
          success: false,
          loading: false,
          error: true
        }
      };
    }

    case ACTION_TYPES.ORDER.SET_ORDER_SIM_CARD_DETAILS: {
      const selectedSimType =
        selectedSimTypeMap[action.value.selectedSimType] ||
        CHOOSE_SIM_TYPE.SIM_TYPE_noSIM;

      return {
        ...state,
        loading: false,
        error: false,
        productOrder: {
          ...state.productOrder,
          sim: {
            hasSim: action.value.hasSim,
            productId: action.value.productId,
            is5GSim: action.value.is5GSim,
            selectedSimType
          }
        },
        selectedSimType
      };
    }

    case ACTION_TYPES.ORDER.ADD_CONTACT_TO_ORDER_LOADING:
    case ACTION_TYPES.ORDER.ADD_SIM_TO_ORDER_LOADING:
    case ACTION_TYPES.ORDER.UPDATE_ORDER_TO_RUN_RULES_LOADING: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case ACTION_TYPES.ORDER.ADD_SIM_TO_ORDER_SUCCESS:
    case ACTION_TYPES.ORDER.UPDATE_ORDER_TO_RUN_RULES_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false
      };
    }

    case ACTION_TYPES.ORDER.ADD_SIM_TO_ORDER_ERROR:
    case ACTION_TYPES.ORDER.ADD_CONTACT_TO_ORDER_ERROR:
    case ACTION_TYPES.ORDER.UPDATE_ORDER_TO_RUN_RULES_ERROR: {
      return {
        ...state,
        loading: false,
        error: true
      };
    }

    case ACTION_TYPES.ORDER.CONTACT_ADDED_TO_ORDER: {
      return {
        ...state,
        loading: false,
        addedToContact: true
      };
    }

    case ACTION_TYPES.ORDER.UPDATE_MAIN_LINE_NUMBER_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case ACTION_TYPES.USER.SET_USER_SELECTION:
    case ACTION_TYPES.ORDER.SET_SEARCH_NUMBERS_SUCCESS:
    case ACTION_TYPES.ORDER.UPDATE_MAIN_LINE_NUMBER_ERROR:
    case ACTION_TYPES.ORDER.UPDATE_MAIN_LINE_NUMBER_SUCCESS: {
      return {
        ...state,
        loading: false,
        productOrderCreated: false
      };
    }

    case ACTION_TYPES.ORDER.RESET_PROCESSING_ORDER: {
      return {
        ...state,
        hasProcessingOrder: false
      };
    }
    case PACKAGE_ACTIONS_TYPES.ORDER_UNDER_PROCESSING: {
      return {
        ...state,
        hasProcessingOrder: true,
        loading: false
      };
    }
    case ACTION_TYPES.ORDER.RESET_ORDER_CREATE_FLAG: {
      return {
        ...state,
        productOrderCreated: false
      };
    }

    default: {
      return state;
    }
  }
};

export default orderReducer;
