import { KeyValue } from '../types/common.types';
import { ACTION_TYPES } from '../constants/actions';
import { FilterGroup, SorterData } from '@common-modules/product-catalogue';
import { Dispatch } from 'redux';
import {
  filterProduct,
  getFilterData,
  getFilterDataWithStatus,
  getProductList,
  getSelectedFilters,
  setSortUrlParams
} from '../helpers/productCatalogHelper';
import {
  ApplyFilterParams,
  FormattedProduct,
  GetFilterDataParams,
  GetProductListParams
} from '../types/productCatalogs.types';
import { CATALOGUE } from '../constants';
import { deepCopy } from '../helpers/common';
import { Plan } from '../types/plan.types';
import { SORT_TYPE } from '../constants/product';
import { trans as t } from '../helpers/localisation';
import { sortByRankingAndTitle } from '../helpers/catalogue-helpers';

export interface ProductCatalogState {
  filterData: FilterGroup[];
  productList: FormattedProduct[];
  originalProductList: FormattedProduct[];
  hasIppFilter: boolean;
  isDoneResetFilter?: boolean;
  backupFilterData: FilterGroup[];
  isLoading: boolean;
  tempSelectedPlan: Plan | null;
  selectedSortType: SORT_TYPE;
  sorterData: SorterData | null;
  rrpFlow?: boolean;
}

const initialState: ProductCatalogState = {
  filterData: [],
  productList: [],
  originalProductList: [],
  hasIppFilter: null,
  isDoneResetFilter: false,
  backupFilterData: [],
  isLoading: true,
  tempSelectedPlan: null,
  selectedSortType: null,
  sorterData: getSortData(),
  rrpFlow: false
};

type ProductCatalogActions = {
  value?: KeyValue | string | boolean | SORT_TYPE;
  type: string;
};

export const productCatalogReducer = (
  state: ProductCatalogState = initialState,
  action: ProductCatalogActions
): ProductCatalogState => {
  switch (action.type) {
    case ACTION_TYPES.PRODUCT_CATALOGS.SET_FILTER_DATA: {
      const filterData = getFilterDataWithStatus({
        filterData: getFilterData(action.value as GetFilterDataParams),
        allProducts: state.originalProductList,
        selectedPlan: (action.value as GetFilterDataParams)?.selectedPlan
      });
      return {
        ...state,
        filterData,
        isDoneResetFilter: false,
        backupFilterData: deepCopy(filterData)
      };
    }

    case ACTION_TYPES.PRODUCT_CATALOGS.RESET_TO_PREVIOUS_FILTER: {
      return {
        ...state,
        filterData: deepCopy(state.backupFilterData),
        isDoneResetFilter: false
      };
    }

    case ACTION_TYPES.PRODUCT_CATALOGS.SELECT_FILTER: {
      const { filterData, selectedPlan } = action.value as ApplyFilterParams;
      const hasIpp = filterData.find(
        item => item.type === CATALOGUE.FILTER_TYPES.PLAN
      )?.items?.[0]?.selected;
      const tempSelectedPlan = hasIpp
        ? state.tempSelectedPlan || selectedPlan
        : selectedPlan;
      const filterDataWithStatus = [
        ...getFilterDataWithStatus({
          filterData,
          allProducts: state.originalProductList,
          selectedPlan: tempSelectedPlan
        })
      ];
      return {
        ...state,
        filterData: filterDataWithStatus,
        tempSelectedPlan: hasIpp ? state.tempSelectedPlan || selectedPlan : null
      };
    }

    case ACTION_TYPES.PRODUCT_CATALOGS.APPLY_FILTER: {
      const { filterData, selectedPlan } = action.value as ApplyFilterParams;
      const filteredProduct = filterProduct({
        products: state.originalProductList,
        filterData,
        selectedPlan: state.tempSelectedPlan || selectedPlan
      });
      const selectedFilters = getSelectedFilters(filterData);
      const filterDataWithStatus = [
        ...getFilterDataWithStatus({
          filterData,
          allProducts: state.originalProductList,
          selectedPlan: state.tempSelectedPlan || selectedPlan
        })
      ];
      let sortedProducts = filteredProduct;
      if (state.selectedSortType) {
        sortedProducts = _sortProducts(state.selectedSortType, filteredProduct);
      }
      return {
        ...state,
        filterData: filterDataWithStatus,
        productList: sortedProducts,
        hasIppFilter: !!selectedFilters.planFilters?.length,
        isDoneResetFilter: false,
        backupFilterData: deepCopy(filterDataWithStatus),
        tempSelectedPlan: null
      };
    }
    case ACTION_TYPES.PRODUCT_CATALOGS.CLEAR_FILTER: {
      const filterData = state.filterData.map(filterGroup => {
        const filterItems = filterGroup.items;
        if (Array.isArray(filterItems)) {
          return {
            ...filterGroup,
            items: filterGroup.items.map(filterItem => ({
              ...filterItem,
              selected: false
            }))
          };
        } else {
          for (const i in filterItems) {
            filterItems[i].value = undefined;
          }
        }
        return filterGroup;
      });

      return {
        ...state,
        filterData,
        backupFilterData: deepCopy(filterData),
        isDoneResetFilter: true,
        hasIppFilter: false,
        tempSelectedPlan: null,
        selectedSortType: null,
        sorterData: {
          ...state.sorterData,
          defaultValue: SORT_TYPE.POPULARITY
        }
      };
    }
    case ACTION_TYPES.PRODUCT_CATALOGS.SET_PRODUCT_LIST: {
      const productListPrams = action.value as GetProductListParams;
      if (state.hasIppFilter === false && productListPrams.queryParams) {
        delete productListPrams.queryParams[CATALOGUE.FILTER_TYPES.PLAN];
      }
      const products = getProductList(productListPrams);
      const filterData = getFilterDataWithStatus({
        filterData: state.filterData,
        allProducts: products.allProduct,
        selectedPlan: (action.value as GetProductListParams)?.selectedPlan
      });
      const productList = state.selectedSortType
        ? _sortProducts(state.selectedSortType, products.filteredProduct)
        : products.filteredProduct;

      return {
        ...state,
        productList,
        originalProductList: products.allProduct,
        filterData,
        isLoading: false,
        isDoneResetFilter: false,
        backupFilterData: deepCopy(filterData)
      };
    }
    case ACTION_TYPES.PRODUCT_CATALOGS.SET_IPP_FILTER: {
      const filterData = state.filterData.map(filterGroup => {
        if (filterGroup.type === CATALOGUE.FILTER_TYPES.PLAN) {
          filterGroup.items[0].selected = action.value as boolean;
        }
        return filterGroup;
      });
      return {
        ...state,
        hasIppFilter: !!action.value as boolean,
        filterData
      };
    }
    case ACTION_TYPES.PRODUCT_CATALOGS.SORT_PRODUCT: {
      const sortType = action.value as SORT_TYPE;
      setSortUrlParams(sortType);
      return {
        ...state,
        productList: _sortProducts(sortType, state.productList),
        selectedSortType: sortType,
        sorterData: {
          ...state.sorterData,
          defaultValue: sortType
        }
      };
    }
    case ACTION_TYPES.PRODUCT_CATALOGS.SET_RRP_FLOW: {
      return {
        ...state,
        rrpFlow: action.value as boolean
      };
    }
  }
  return state;
};

function getSortData(defaultValue = SORT_TYPE.POPULARITY): SorterData {
  return {
    label: t('TEXT_SORT_BY') as string,
    defaultValue,
    items: [
      { text: t('TEXT_POPULARITY') as string, value: SORT_TYPE.POPULARITY },
      { text: t('TEXT_LATEST') as string, value: SORT_TYPE.LATEST },
      {
        text: t('TEXT_PRICE_HIGH_LOW') as string,
        value: SORT_TYPE.PRICE_HIGH_TO_LOW
      },
      {
        text: t('TEXT_PRICE_LOW_HIGH') as string,
        value: SORT_TYPE.PRICE_LOW_TO_HIGH
      },
      { text: 'A-Z', value: SORT_TYPE.AZ },
      { text: 'Z-A', value: SORT_TYPE.ZA }
    ]
  };
}

const _sortProducts = (sortType: SORT_TYPE, products: FormattedProduct[]) => {
  let sortedList = products;
  switch (sortType) {
    case SORT_TYPE.POPULARITY: {
      sortedList = [...products.sort(sortByRankingAndTitle)];
      break;
    }
    case SORT_TYPE.AZ: {
      sortedList = [
        ...products.sort((a, b) => {
          return a.title.localeCompare(b.title);
        })
      ];
      break;
    }
    case SORT_TYPE.ZA: {
      sortedList = [
        ...products.sort((a, b) => {
          return b.title.localeCompare(a.title);
        })
      ];
      break;
    }

    case SORT_TYPE.PRICE_LOW_TO_HIGH: {
      sortedList = [
        ...products.sort((a, b) => {
          return a.priceValue - b.priceValue;
        })
      ];
      break;
    }

    case SORT_TYPE.PRICE_HIGH_TO_LOW: {
      sortedList = [
        ...products.sort((a, b) => {
          return b.priceValue - a.priceValue;
        })
      ];
      break;
    }
    case SORT_TYPE.LATEST: {
      sortedList = [
        ...products.sort((current, next) => {
          return next.launchDate - current.launchDate;
        })
      ];
      break;
    }
  }
  return sortedList;
};

export const resetToPreviousFilter = () => (dispatch: Dispatch) => {
  dispatch({
    type: ACTION_TYPES.PRODUCT_CATALOGS.RESET_TO_PREVIOUS_FILTER
  });
};

export const selectFilter = (value: ApplyFilterParams) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: ACTION_TYPES.PRODUCT_CATALOGS.SELECT_FILTER,
    value
  });
};

export const setFilterData = (value: GetFilterDataParams) => (
  dispatch: Dispatch
) => {
  dispatch({ type: ACTION_TYPES.PRODUCT_CATALOGS.SET_FILTER_DATA, value });
};

export const setProductList = (value: GetProductListParams) => (
  dispatch: Dispatch
) => {
  dispatch({ type: ACTION_TYPES.PRODUCT_CATALOGS.SET_PRODUCT_LIST, value });
};

export const applyFilter = (value: ApplyFilterParams) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: ACTION_TYPES.PRODUCT_CATALOGS.APPLY_FILTER,
    value
  });
};

export const clearFilter = () => (dispatch: Dispatch) => {
  dispatch({
    type: ACTION_TYPES.PRODUCT_CATALOGS.CLEAR_FILTER
  });
};

export const setIppFilter = (value: boolean) => (dispatch: Dispatch) => {
  dispatch({ type: ACTION_TYPES.PRODUCT_CATALOGS.SET_IPP_FILTER, value });
};

export const sortProducts = (value: SORT_TYPE) => (dispatch: Dispatch) => {
  dispatch({ type: ACTION_TYPES.PRODUCT_CATALOGS.SORT_PRODUCT, value });
};

export const setRRPFlow = (isRRP: boolean) => (dispatch: Dispatch) => {
  dispatch({ type: ACTION_TYPES.PRODUCT_CATALOGS.SET_RRP_FLOW, value: isRRP });
};
