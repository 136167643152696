export const ACTION_TYPES = {
  CHECKOUT: {
    UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS'
  },
  ORDER_SUMMARY: {
    CLEAR_PAYMENT_INTENT: 'CLEAR_PAYMENT_INTENT'
  },
  ORDER_SUBMISSION: {
    ORDER_SUBMISSION_LOADING: 'ORDER_SUBMISSION_LOADING',
    ORDER_SUBMISSION_SUCCESS: 'ORDER_SUBMISSION_SUCCESS',
    ORDER_SUBMISSION_ERROR: 'ORDER_SUBMISSION_ERROR',
    REST_SUBMISSION: 'REST_SUBMISSION'
  },
  PAYMENT: {
    RESET_PAYMENT: 'RESET_PAYMENT',
    CLEAR_BUY_FLOW_DATA: 'CLEAR_BUY_FLOW_DATA'
  },
  LOGS: {
    SEND_LOGS: 'SEND_LOGS',
    PUSH_API_LOG: 'PUSH_API_LOG',
    PUSH_USER_JOURNEY: 'PUSH_USER_JOURNEY',
    RESET_LOG: 'RESET_LOG'
  },
  AUTH: {
    RESET_WORRY_FREE_STATUS: 'RESET_WORRY_FREE_STATUS',
    SET_WORRY_FREE_DATA: 'SET_WORRY_FREE_DATA'
  },
  USER: {
    RESET_USER_FOR_CHECKOUT: 'RESET_USER_FOR_CHECKOUT',
    SET_USER_SELECTION: 'SET_USER_SELECTION',
    RESET_USER: 'RESET_USER',
    SET_USER: 'SET_USER',
    SET_USER_INFORMATION: 'SET_USER_INFORMATION',
    CALLER_BY_CONTACT_SUCCESS: 'CALLER_BY_CONTACT_SUCCESS',
    SET_USER_LOADING: 'SET_USER_LOADING',
    SET_USER_ERROR: 'SET_USER_ERROR',
    SET_USER_PRODUCTS: 'SET_USER_PRODUCTS',
    SET_CIS_INFORMATION: 'SET_CIS_INFORMATION',
    SET_CIS_LOADING: 'SET_CIS_LOADING',
    SET_CIS_ERROR: 'SET_CIS_ERROR',
    SET_SELECTED_SERVICE: 'SET_SELECTED_SERVICE',
    SET_USER_MAIN_LINES: 'SET_USER_MAIN_LINES',
    CIS_GET_MYINFO_ERROR_STATUS: 'CIS_GET_MYINFO_ERROR_STATUS',
    CIS_GET_MYINFO_SUCCESS: 'CIS_GET_MYINFO_SUCCESS',
    UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS'
  },
  CART: {
    SHOPPING_CART_LOADING: 'SHOPPING_CART_LOADING',
    SHOPPING_CART_ERROR: 'SHOPPING_CART_ERROR',
    SHOPPING_CART_SUCCESS: 'SHOPPING_CART_SUCCESS',
    EMPTY_CART_LOADING: 'EMPTY_CART_LOADING',
    EMPTY_CART_SUCCESS: 'EMPTY_CART_SUCCESS',
    EMPTY_CART_ERROR: 'EMPTY_CART_ERROR',
    CHECKOUT_LOADING: 'CHECKOUT_LOADING',
    CHECKOUT_SUCCESS: 'CHECKOUT_SUCCESS',
    CHECKOUT_ERROR: 'CHECKOUT_ERROR',
    CHECKOUT_ERROR_SCENARIO_CHECK: 'CHECKOUT_ERROR_SCENARIO_CHECK',
    CHECKOUT_RESET_ERROR_SCENARIO_CHECK: 'CHECKOUT_RESET_ERROR_SCENARIO_CHECK',
    CHECKOUT_ORDER_ERROR: 'CHECKOUT_ORDER_ERROR',
    CLEAR_CHECKOUT_ERROR: 'CLEAR_CHECKOUT_ERROR',
    ACKNOWLEDGE_NOTIFICATION: 'ACKNOWLEDGE_NOTIFICATION',
    RRP_SHOPPING_CART_SUCCESS: 'RRP_SHOPPING_CART_SUCCESS',
    BIB_SET_DISPLAY_DATA: 'BIB_SET_DISPLAY_DATA'
  },
  ADDONS: {
    RESET_UNKNOWN_ERROR: 'RESET_UNKNOWN_ERROR',
    ADDONS_FETCH_LOADING: 'ADDONS_FETCH_LOADING'
  },
  ORDER: {
    RESET_ORDER: 'RESET_ORDER',
    SET_PORT_IN_NUMBER: 'SET_PORT_IN_NUMBER',
    UPDATE_PRODUCT_ORDER: 'UPDATE_PRODUCT_ORDER',
    SET_PRODUCT_ORDER_SUCCESS: 'SET_PRODUCT_ORDER_SUCCESS',
    SET_PRODUCT_ORDER_LOADING: 'SET_PRODUCT_ORDER_LOADING',
    SET_ORDER_LOADING: 'SET_ORDER_LOADING',
    SET_PRODUCT_ORDER_ERROR: 'SET_PRODUCT_ORDER_ERROR',
    SET_RESERVE_RESOURCE_SUCCESS: 'SET_RESERVE_RESOURCE_SUCCESS',
    SET_RESERVE_RESOURCE_ERROR: 'SET_RESERVE_RESOURCE_ERROR',
    SET_ORDER_SIM_CARD_DETAILS: 'SET_ORDER_SIM_CARD_DETAILS',
    UPDATE_ORDER_TO_RUN_RULES_LOADING: 'UPDATE_ORDER_TO_RUN_RULES_LOADING',
    UPDATE_ORDER_TO_RUN_RULES_SUCCESS: 'UPDATE_ORDER_TO_RUN_RULES_SUCCESS',
    UPDATE_ORDER_TO_RUN_RULES_ERROR: 'UPDATE_ORDER_TO_RUN_RULES_ERROR',
    ADD_SIM_TO_ORDER_LOADING: 'ADD_SIM_TO_ORDER_LOADING',
    ADD_SIM_TO_ORDER_SUCCESS: 'ADD_SIM_TO_ORDER_SUCCESS',
    ADD_SIM_TO_ORDER_ERROR: 'ADD_SIM_TO_ORDER_ERROR',
    ADD_CONTACT_TO_ORDER_LOADING: 'ADD_CONTACT_TO_ORDER_LOADING',
    ADD_CONTACT_TO_ORDER_ERROR: 'ADD_CONTACT_TO_ORDER_ERROR',
    CONTACT_ADDED_TO_ORDER: 'CONTACT_ADDED_TO_ORDER',
    UPDATE_MAIN_LINE_NUMBER_LOADING: 'UPDATE_MAIN_LINE_NUMBER_LOADING',
    UPDATE_MAIN_LINE_NUMBER_SUCCESS: 'UPDATE_MAIN_LINE_NUMBER_SUCCESS',
    UPDATE_MAIN_LINE_NUMBER_ERROR: 'UPDATE_MAIN_LINE_NUMBER_ERROR',
    SET_PRODUCTS_LIST: 'SET_PRODUCTS_LIST',
    RESET_PROCESSING_ORDER: 'RESET_PROCESSING_ORDER',
    VALIDATE_REQUEST_PAYMENT_FAILED: 'VALIDATE_REQUEST_PAYMENT_FAILED',
    RESET_SEARCH_NUMBERS: 'RESET_SEARCH_NUMBERS',
    RESET_CHOOSE_NUMBER_ERROR: 'RESET_CHOOSE_NUMBER_ERROR' + '',
    RESET_ORDER_CREATE_FLAG: 'RESET_ORDER_CREATE_FLAG',
    SET_SEARCH_NUMBERS_SUCCESS: 'SET_SEARCH_NUMBERS_SUCCESS'
  },
  DELIVERY: {
    CLEAR_DELIVERY: 'CLEAR_DELIVERY',
    RESET_ERROR: 'RESET_ERROR'
  },
  FULFILMENT: {
    RESERVE_EQUIPMENT_SUCCESS: 'RESERVE_EQUIPMENT_SUCCESS',
    SUBMIT_FULFILLMENT_SUCCESS: 'SUBMIT_FULFILLMENT_SUCCESS',
    SET_COLLECT_AT_STORE_TIME_SLOTS_LOADING:
      'SET_COLLECT_AT_STORE_TIME_SLOTS_LOADING'
  },
  CREDIT_LIMIT_REVIEW: {
    UPDATE_FLOW_TYPE: 'UPDATE_FLOW_TYPE'
  },
  THIRD_PARTY: {
    UPDATE_INDICATOR: 'UPDATE_INDICATOR',
    UPDATE_INDICATOR_ERROR: 'UPDATE_INDICATOR_ERROR',
    UPDATE_INDICATOR_SUCCESS: 'UPDATE_INDICATOR_SUCCESS'
  },
  IPHONE: {
    SET_ROI_TOKEN: 'SET_ROI_TOKEN',
    SET_OTP_VISIBLE: 'SET_OTP_VISIBLE',
    RESET_DATA: 'IPHONE_RESET_DATA',
    RESET_DATA_EXCLUDE_ROITOKEN: 'RESET_DATA_EXCLUDE_ROITOKEN',
    SET_FORM_DATA: 'SET_FORM_DATA',
    SET_VALIDATE_ERROR: 'SET_VALIDATE_ERROR',
    RESET_FORM_DATA: 'RESET_FORM_DATA'
  },
  TOKEN: {
    RETRIEVE_TOKEN_LOADING: 'RETRIEVE_TOKEN_LOADING',
    RETRIEVE_TOKEN_SUCCESS: 'RETRIEVE_TOKEN_SUCCESS',
    RETRIEVE_TOKEN_ERROR: 'RETRIEVE_TOKEN_ERROR'
  },
  RRP: {
    SET_RRP_MASTER_ORDERID: 'SET_RRP_MASTER_ORDERID',
    SET_FULFILMENT_STATE: 'SET_FULFILMENT_STATE',
    RESET_FULFILMENT_STATE: 'RESET_FULFILMENT_STATE',
    SET_RRP_FLOW: 'SET_RRP_FLOW',
    RESET_RRP_FLOW: 'RESET_RRP_FLOW',
    RRP_CLEAR_ORDER_DATA: 'RRP_CLEAR_ORDER_DATA',
    INIT: 'RRP_INIT'
  },
  PRODUCT_CATALOGS: {
    SET_FILTER_DATA: 'PRODUCT_CATALOGS_SET_FILTER_DATA',
    APPLY_FILTER: 'PRODUCT_CATALOGS_APPLY_FILTER',
    CLEAR_FILTER: 'PRODUCT_CATALOGS_CLEAR_FILTER',
    SET_PRODUCT_LIST: 'PRODUCT_CATALOGS_SET_PRODUCT_LIST',
    SET_IPP_FILTER: 'PRODUCT_CATALOGS_SET_IPP_FILTER',
    SELECT_FILTER: 'SELECT_FILTER',
    RESET_TO_PREVIOUS_FILTER: 'RESET_TO_PREVIOUS_FILTER',
    SORT_PRODUCT: 'SORT_PRODUCT',
    SET_RRP_FLOW: 'PRODUCT_CATALOG_SET_RRP_FLOW'
  },
  USER_IDENTITY_CHECK: {
    SINGPASS_SUCCESS_CODE: 'code=',
    SINGPASS_STATE: 'state=345',
    SINGPASS_ERROR_CODE: 'error=access_denied',
    CLEAR_MY_INFO_URL: 'CLEAR_MY_INFO_URL'
  },
  SIMTYPE: {
    SET_SIMTYPE: 'SET_SIMTYPE',
    RESET_SIMTYPE: 'RESET_SIMTYPE'
  },
  USER_PROMOTIONS: {
    FETCH_USER_MAIN_PRODUCTS_LOADING: 'FETCH_USER_MAIN_PRODUCTS_LOADING',
    FETCH_USER_MAIN_PRODUCTS_SUCCESS: 'FETCH_USER_MAIN_PRODUCTS_SUCCESS',
    FETCH_USER_MAIN_PRODUCTS_ERROR: 'FETCH_USER_MAIN_PRODUCTS_ERROR'
  },
  APIGEE_AUTH: {
    GET_APIGEE_TOKEN_LOADING: 'GET_APIGEE_TOKEN_LOADING',
    GET_APIGEE_TOKEN_SUCCESS: 'GET_APIGEE_TOKEN_SUCCESS',
    GET_APIGEE_TOKEN_FAILURE: 'GET_APIGEE_TOKEN_FAILURE'
  },
  CATALOG_BANNER: {
    LOAD_CATALOG_BANNER_REQUEST: 'LOAD_CATALOG_BANNER_REQUEST',
    LOAD_CATALOG_BANNER_SUCCESS: 'LOAD_CATALOG_BANNER_SUCCESS',
    LOAD_CATALOG_BANNER_ERROR: 'LOAD_CATALOG_BANNER_ERROR'
  },
  PLAN: {
    EXCLUSIVE_VALIDATION_HIDE: 'EXCLUSIVE_VALIDATION_HIDE'
  },
  PROMOTION: {
    SAVE_PROMOTION_ORDER_DETAILS: 'SAVE_PROMOTION_ORDER_DETAILS',
    SET_SPECIAL_PROMOTION: 'SET_SPECIAL_PROMOTION',
    CLEAR_SPECIAL_PROMOTION: 'CLEAR_SPECIAL_PROMOTION'
  },
  SETTINGS: {
    SET_APP_CONFIG: 'SET_APP_CONFIG',
    RESET_APP_CONFIG: 'RESET_APP_CONFIG'
  }
};
